/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { ServicesTotal } from '../DashboardWrapper'
import ReactApexChart from 'react-apexcharts'


type Props = {
  title?:string,
  className: string
  total?:number,
  color?:string,
  onsite?:number,
  online?:number,
  services_headers?: Array<string>,
  timing_headers?: Array<string>,
  centre_headers?: Array<string>,
  services_total?: Array<ServicesTotal>,
  data: any,
}

const RecentData: React.FC<Props> = ({services_headers, className, data, timing_headers, centre_headers, services_total, title, color}) => {
  const chartDataOptions: any = {};
  services_total?.forEach(services_total => {
    chartDataOptions[services_total.centre] = {
      series: services_total.chart_data || [],
      labels: services_headers,
      legend: {
        show: false
      },
      options: {
        chart: {
          type: 'donut',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 500
            },
          }
        }]
      },
    }
  });

  return (
    <div className={`card ${className} bg-gray-100 align-items-center`}>
      {/* begin::Body */}
      <div className='card-body p-0 row'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 h-275px w-100`} style={{ background: color }}>
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            {title === 'Tng' ? 
            <h3 className='m-0 text-white fw-bold fs-3' >Recent {title} Attendance</h3>: 
            <h3 className='m-0 text-white fw-bold fs-3' >Recent {title} Services</h3>}
            <div className='ms-1'>
              {/* begin::Menu */}
       
              {/* end::Menu */}
            </div>
          </div>
          {/* end::Heading */}
        </div>
        {/* end::Header */}
        <div className='row'>
        {centre_headers?.map((centre_header, index) => (
          <div className='col-4 px-9' style={{ marginTop: '-200px' }}>
            <h3 className='m-0 text-white fw-bold fs-3 mb-2' style={{ textAlign: 'center' }}>{centre_header}</h3>
            <table 
            className='table table-row-dashed table-hover square bg-white rounded-2'
            style={{overflow:'hidden'}}
            >
              <thead className='bg-gray-300'>
                <tr className='fw-bold text-bold align-center'>
                  <th style={{ textAlign: 'center' }}>Services</th>
                  <th style={{ textAlign: 'center' }}>First Service</th>
                  <th style={{ textAlign: 'center' }}>Second Service</th>
                </tr>
              </thead>
              <tbody>
                {services_headers?.map((service_header, index) => (
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'></div>
                        <div className='d-flex justify-content-start flex-column'>
                          {service_header}
                        </div>
                      </div>
                    </td>
                    {timing_headers?.map((timing, index) => (
                      <td key={service_header + ' ' + index}>
                        <div className='d-flex align-items-end justify-content-end px-4'>
                          <div className='symbol symbol-45px me-5'></div>
                          <div className='d-flex justify-content-end flex-column'>
                            {
                            data[centre_header][service_header][timing].special ?
                            data[centre_header][service_header][timing].attendance + "*" :
                            data[centre_header][service_header][timing].attendance
                            }
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
                  <tr key={index + 1}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'></div>
                        <div className='d-flex justify-content-start flex-column'>
                          Total
                        </div>
                      </div>
                    </td>
                    {timing_headers?.map((timing, index) => (
                      <td key={'Total ' + index}>
                        <div className='d-flex align-items-end justify-content-end px-4'>
                          <div className='symbol symbol-45px me-5'></div>
                          <div className='d-flex justify-content-end flex-column'>
                            {data[centre_header]['Total'][timing].attendance}
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
              </tbody>
            </table>
            <div>
            <ReactApexChart options={chartDataOptions[centre_header]} series={chartDataOptions[centre_header].series} type="donut" />
            </div>
          </div>
        ))}
        </div>

            {/* end::Table container */}
        </div>
        {/* end::Items */}
      {/* end::Body */}
    </div>
  )
}

export {RecentData}
