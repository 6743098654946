/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'
import {Navigate, Route} from 'react-router-dom'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

type Data = {
  menu: string
  read: boolean
  write: boolean
  roles_id: number
}

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [accessUser, setAccessUser] = useState<Data[]>([])

  useEffect(() => {
    axios
      .get(`${API_URL}user/get-current-user-access`)
      .then((res) => {
        setAccessUser(res.data)
      })
      .catch((error) => console.error(`Error:${error}`))
  }, [])

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Module</span>
        </div>
      </div>

      {accessUser
        .filter((menu) => menu.menu == 'Worship')
        .map((item, index) => {
          return item.menu == 'Worship' ? (
            <div key={index}>
              <AsideMenuItemWithSub
                to='/crafted/accounts'
                title='Worship Service'
                icon='/media/icons/duotune/general/gen008.svg'
                fontIcon='bi-person'
              >
                {item.write && (
                  <AsideMenuItem
                    to='/module/worship/registration'
                    title='Data Entry'
                    hasBullet={true}
                  />
                )}
                {item.read && (
                  <AsideMenuItem
                    to='/module/worship/weekly'
                    title='Weekly Worship Attendance'
                    hasBullet={true}
                  />
                )}
              </AsideMenuItemWithSub>
            </div>
          ) : (
            <div key={index}></div>
          )
        })}

      {accessUser
        .filter((menu) => menu.menu == 'TNG')
        .map((item, index) => {
          return item.menu == 'TNG' ? (
            <div key={index}>
              <AsideMenuItemWithSub
                to='/crafted/accounts'
                title='TNG '
                icon='/media/icons/duotune/general/gen008.svg'
                fontIcon='bi-person'
              >
                {item.write && (
                  <AsideMenuItem
                    to='/module/tng/tngregistration'
                    title='Data Entry'
                    hasBullet={true}
                  />
                )}
                {item.write && (
                  <AsideMenuItem
                    to='/module/tng/tngaddlinfo'
                    title='TNG Additional Info Entry'
                    hasBullet={true}
                  />
                )}
                {item.read && (
                  <AsideMenuItem
                    to='/module/tng/tngweekly'
                    title='Weekly TNG Attendance'
                    hasBullet={true}
                  />
                )}
              </AsideMenuItemWithSub>
            </div>
          ) : (
            <div key={index}></div>
          )
        })}

      {accessUser.map((item, index) => {
        return item.menu == 'Events' ? (
          <div key={index}>
            <AsideMenuItemWithSub
              to='/crafted/accounts'
              title='Events'
              icon='/media/icons/duotune/general/gen008.svg'
              fontIcon='bi-person'
            >
              {item.write && (
                <AsideMenuItem
                  to='/module/event/eventregistration'
                  title='Data Entry'
                  hasBullet={true}
                />
              )}
              {item.read && (
                <AsideMenuItem to='/module/event/eventlist' title='Events List' hasBullet={true} />
              )}
            </AsideMenuItemWithSub>
          </div>
        ) : (
          <div key={index}></div>
        )
      })}

      {accessUser.map((item, index) => {
        return item.menu == 'Reports' ? (
          <div key={index}>
            {item.read && (
              <>
                <div className='menu-item'>
                  <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Report</span>
                  </div>
                </div>

                <AsideMenuItemWithSub
                  to='/crafted/accounts'
                  title='Combined Report'
                  icon='/media/icons/duotune/general/gen022.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='/module/combinedreport/combinedaverage'
                    title='Combined Average Attendances'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/module/combinedreport/monthlyattendance'
                    title='Monthly Attendance'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/module/combinedreport/attendancetrend'
                    title='Attendance Trend'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/module/combinedreport/worshipdetails'
                    title='Worship History'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/module/combinedreport/tngdetails'
                    title='TNG History'
                    hasBullet={true}
                  />
                </AsideMenuItemWithSub>

                <AsideMenuItem
                  to='/module/eventreport/listevent'
                  icon='/media/icons/duotune/general/gen022.svg'
                  title='Events'
                  fontIcon='bi-layers'
                />
              </>
            )}
          </div>
        ) : (
          <div key={index}></div>
        )
      })}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>

      {accessUser
        .filter((menu) => menu.menu == 'System')
        .map((item, index) => {
          return item.menu == 'System' ? (
            <div key={index}>
              <AsideMenuItemWithSub
                to='/apps/system'
                title='System Management'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/general/gen051.svg'
              >
                <AsideMenuItem to='/apps/system/centers' title='Centres' hasBullet={true} />
                <AsideMenuItem to='/apps/system/service' title='Services Type' hasBullet={true} />
                <AsideMenuItem to='/apps/system/tnglevel' title='TNG Levels' hasBullet={true} />

                <AsideMenuItemWithSub to='/apps/system/events' title='Event' hasBullet={true}>
                  <AsideMenuItem to='/apps/system/event' title='Event Type' hasBullet={true} />
                  <AsideMenuItem to='/apps/system/region' title='Region' hasBullet={true} />
                  <AsideMenuItem to='/apps/system/thrust' title='Thrust' hasBullet={true} />
                  <AsideMenuItem to='/apps/system/frequency' title='Frequency' hasBullet={true} />
                  <AsideMenuItem
                    to='/apps/system/category'
                    title='Discipleship Process'
                    hasBullet={true}
                  />
                  {/* <AsideMenuItem
                    to='/apps/system/faithexploits'
                    title='Faith Exploits'
                    hasBullet={true}
                  /> */}
                  <AsideMenuItem to='/apps/system/mode' title='Mode' hasBullet={true} />
                </AsideMenuItemWithSub>
                <AsideMenuItem to='/apps/system/tableau' title='Tableau Embed' hasBullet={true} />
                <AsideMenuItem to='/apps/system/logaccess' title='System Log' hasBullet={true} />
              </AsideMenuItemWithSub>
            </div>
          ) : (
            <div key={index}></div>
          )
        })}

      {accessUser
        .filter((menu) => menu.menu == 'User')
        .map((item, index) => {
          return item.menu == 'User' ? (
            <div key={index}>
              <AsideMenuItemWithSub
                to='/apps/user-management'
                title='User Management'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/communication/com006.svg'
              >
                <AsideMenuItem to='/apps/user-management/users' title='Add User' hasBullet={true} />
                <AsideMenuItem
                  to='/apps/user-management/access'
                  title='Access Control'
                  hasBullet={true}
                />
                <AsideMenuItem
                  to='/apps/user-management/roles'
                  title='Roles Master'
                  hasBullet={true}
                />
              </AsideMenuItemWithSub>
            </div>
          ) : (
            <div key={index}></div>
          )
        })}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a target='_blank' className='menu-link'>
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Version {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>
    </>
  )
}
