import axios from 'axios'
import React, {useState, useEffect} from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'
import {ToastContainer} from 'react-toastify'
import Utilities from '../../services/Utilities'
import 'react-toastify/dist/ReactToastify.css'
import {useLocation, useNavigate} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import DatePicker, {registerLocale} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import hu from 'date-fns/locale/hu'
const API_URL = process.env.REACT_APP_API_URL

type Serviceslist = {
  id: number
  name: string
  idsub: number
  namesub: string
}
type Services = {
  id: number
  name: string
}

type Centers = {
  id: number
  subcount: string
  name: string
}

type Time = {
  id: number
  service_id: number
  subcount: string
  name: string
}

type Worship = {
  id: number
  date: string
  servicetype_id: number
  centre_id: number
  service_id: number
  special: boolean
  timing_id: number
  onsite_att: number
  online_att: number
  first_timer: number
  salvation: number
  note: string
  created_at: string
  created_by: string | undefined
}

type WorshipData = {
  id: number
  date: string
  servicetype_id: number
  servicetype: string
  centre_id: number
  center: string
  service_id: number
  service: string
  timing_id: number
  timing: string
  onsite_att: number
  online_att: number
  first_timers: number
  salvations: number
  note: string
  created_at: string
}

const WorshipRegistration = () => {
  const {toasts} = Utilities()
  const [datas, setDatas] = useState<WorshipData[]>([])
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [loading, setLoading] = useState(false)

  const [serviceslist, setServiceslist] = useState<Serviceslist[]>([])

  const [services, setServices] = useState<Services[]>([])

  const [servicessub, setServicessub] = useState<Serviceslist[]>([])

  const [centers, setCenters] = useState<Centers[]>([])

  const [time, setTime] = useState<Time[]>([])

  const [timeset, setTimeset] = useState<Time[]>([])


  registerLocale('hu', hu)

  const {state} = useLocation()
  const {register, handleSubmit, reset, resetField, setValue} = useForm<Worship>({})

  const [timingdis, setTimingdis] = useState(false)
  const [mode, setMode] = useState(false)
  useEffect(() => {
    const history = createBrowserHistory()
    history.replace('', null)

    axios
      .get(`${API_URL}service-type/get-service-type-and-sub-types`)
      .then((res) => {
        setServiceslist(res.data.data)
      })
      .catch((error) => console.error(`Error:${error}`))

    axios
      .get(`${API_URL}service-type/get-all-service-types`)
      .then((res) => {
        setServices(res.data.data)
      })
      .catch((error) => console.error(`Error:${error}`))

    axios
      .get(`${API_URL}center/get-all-centers`)
      .then((res) => {
        setCenters(res.data.data)
      })
      .catch((error) => console.error(`Error:${error}`))

    axios
      .get(`${API_URL}timing/get-all-service-timings`)
      .then((res) => {
        setTime(res.data.data)
      })
      .catch((error) => console.error(`Error:${error}`))

    if (state != null) {
      setMode(false)
      axios
        .get(`${API_URL}worship/get-worship/${state}`)
        .then((res) => {
          setDatas(res.data.data)

          reset({
            id: res.data.data[0]['id'],
            date: res.data.data[0]['date'],
            servicetype_id: res.data.data[0]['servicestype_id'],
            service_id: res.data.data[0]['services_id'],
            centre_id: res.data.data[0]['centers_id'],
            timing_id: res.data.data[0]['timings_id'],
            onsite_att: res.data.data[0]['onsite_att'],
            first_timer: res.data.data[0]['first_timers'],
            salvation: res.data.data[0]['salvations'],
            special: res.data.data[0]['special'],
            note: res.data.data[0]['note'],
          })

          setStartDate(new Date(moment(res.data.data[0]['date']).format()))
        })
        .catch((error) => console.error(`Error:${error}`))
    } else {
      setMode(true);
    }
  }, []);

  const selectservicetype = (id: number) => {
    const data = serviceslist.filter((newData) => newData.id === id);
    resetField('service_id');
    setServicessub(data);
  }

  const selectservice = (e: any) => {
    var id: number = e
    const data = time.filter((newData) => newData.service_id == id)
    setTimeset(data);
    if (data.length == 0) {
      setTimingdis(true)
      resetField('timing_id');
    } else {
      setTimingdis(false)
      setValue('timing_id', data[0].id);
    }
  }

  function resetForm() {
    resetField('date')
    resetField('servicetype_id')
    resetField('centre_id')
    resetField('service_id')
    resetField('timing_id')
    resetField('onsite_att')
    resetField('online_att')
    resetField('first_timer')
    resetField('salvation')
    resetField('created_at')
    resetField('special')
    resetField('note')
  }

  const navigate = useNavigate()
  const onSubmit: SubmitHandler<Worship> = (data) => {
    data.date = moment(startDate).format('YYYY-MM-DD')
    setLoading(true)
    if (mode) {
      axios
        .post(`${API_URL}worship/add-worship`, data)
        .then((res) => {
          if (res.data.data === true) {
            toasts('success', 'Data Saved Successfully !')
            resetForm()
            setLoading(false)
          } else {
            setLoading(false)
            resetForm()
            toasts('error', res.data.message)
          }
        })
        .catch((error) => {
          setLoading(false)
          toasts('error', 'Error adding worship, please check if this is a duplicate')
        })
    } else {
      axios
        .put(`${API_URL}worship/update-worship`, data)
        .then((res) => {
          if (res.data.data === true) {
            setLoading(false)
            resetForm()
            navigate('/module/worship/weekly', {state: true})
          } else {
            setLoading(false)
            toasts('error', 'Cannot modify data, You are not the owner !')
          }
        })
        .catch((error) => console.error(`Error:${error}`))
    }
  }

  function discardEdit() {
    setMode(true)

    if (mode == false) {
      navigate('/module/worship/weekly', {state: false})
    }
  }

  return (
    <>
      <ToastContainer theme={'colored'} />
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Data Entry</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form className='form' onSubmit={handleSubmit(onSubmit)}>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Date</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <DatePicker
                        // disabled={state != null}
                        dateFormat='dd MMM yyyy'
                        selected={startDate}
                        calendarStartDay={1}
                        onChange={(date) => {
                          setStartDate(date)
                        }}
                        includeDateIntervals={[{start: new Date('1960/01/1'), end: new Date()}]}
                        className='form-control form-control-lg mb-3 mb-lg-0'
                      />
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Centre</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  {state === null ? (
                    <select
                      required={true}
                      {...register('centre_id')}
                      name='centre_id'
                      className='form-select form-select-lg fw-bold'
                    >
                      <option value=''>Select</option>
                      {centers.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold col-form-label fs-6'>
                        {' '}
                        <span>{datas[0]?.center}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Service Type
                </label>

                <div className='col-lg-8 fv-row'>
                  {state === null ? (
                    <div className='d-flex align-items-center mt-3'>
                      {services.map((item, index) => {
                        return (
                          <label
                            key={index}
                            className='form-check form-check-inline form-check-solid me-5'
                          >
                            <input
                              required={true}
                              {...register('servicetype_id')}
                              onChange={() => selectservicetype(item.id)}
                              className='form-check-input'
                              value={item.id}
                              name='servicetype_id'
                              type='radio'
                            />
                            <span className='fw-bold ps-2 fs-6'>{item.name}</span>
                          </label>
                        )
                      })}
                    </div>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold col-form-label fs-6'>
                        {' '}
                        <span>{datas[0]?.servicetype}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Service</span>
                </label>

                <div className='col-lg-4 col-sm-6 fv-row'>
                  {state === null ? (
                    <div className='d-flex align-items-center'>
                      <select
                        required={true}
                        {...register('service_id')}
                        name='service_id'
                        onChange={(e) => selectservice(e.target.value)}
                        className='form-select form-select-lg fw-bold'
                      >
                        <option value=''>Select</option>
                        {servicessub.map((item, index) => {
                          return (
                            <option key={index} value={item.idsub}>
                              {item.namesub}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-8 fw-bold col-form-label fs-6'>
                        {' '}
                        <span>{datas[0]?.service}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='col-lg-4 col-sm-4 fv-row d-flex align-items-center'>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      {...register('special')}
                      className='form-check-input'
                      name='special'
                      type='checkbox'
                    />
                    <span className='fw-bold ps-2 fs-6'>Special Service</span>
                  </label>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Time</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  {state === null ? (
                    <select
                      required={true}
                      {...register('timing_id')}
                      name='timing_id'
                      disabled={timingdis}
                      className='form-select form-select-lg fw-bold'
                    >
                      {timeset.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold col-form-label fs-6'>
                        {' '}
                        <span>{datas[0]?.timing}</span>
                      </div>
                    </div>
                  )}

                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Attendee</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    required
                    {...register('onsite_att', {
                      valueAsNumber: true,
                    })}
                    name='onsite_att'
                    type='number'
                    min={1}
                    className='form-control form-control-lg'
                    placeholder='0'
                  />
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Newcomer</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    {...register('first_timer', {
                      valueAsNumber: true,
                    })}
                    name='first_timer'
                    type='number'
                    min={0}
                    className='form-control form-control-lg'
                    placeholder='0'
                  />
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Salvations</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    {...register('salvation', {
                      valueAsNumber: true,
                    })}
                    name='salvation'
                    type='number'
                    min={0}
                    className='form-control form-control-lg'
                    placeholder='0'
                  />
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Note</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    {...register('note')}
                    name='note'
                    type='text'
                    className='form-control form-control-lg'
                    placeholder='Please type your notes here'
                  />
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='reset'
                onClick={discardEdit}
                className='btn btn-default'
                disabled={loading}
              >
                Cancel
              </button>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default WorshipRegistration
