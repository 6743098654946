import axios from 'axios';
import { useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import {  toast } from 'react-toastify';

export default function Utilities(){
   
    const toasts = (status,meessage) => {
        switch(status) {
            case 'error':
                toast.error(meessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
              break;
            default:
                toast.success(meessage, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
          }
       
    }
    
    return {
        toasts
    }
}