import axios from 'axios'
import React, {useState, useEffect} from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'
import {ToastContainer} from 'react-toastify'
import Utilities from '../../services/Utilities'
import 'react-toastify/dist/ReactToastify.css'
import {useLocation, useNavigate} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
const API_URL = process.env.REACT_APP_API_URL

type Centers = {
  id: number
  subcount: string
  name: string
}

type Levels = {
  id: number
  subcount: string
  name: string
}

type Time = {
  id: number
  level_id: number
  subcount: string
  name: string
}

type Tng = {
  id: number
  date: string
  centre_id: number
  level_id: number
  timing_id: number
  parent_server: number | 0
  adult_dmk: number | 0
  youth_dmk: number | 0
  salvation: number | 0
}

type TngData = {
  id: number
  date: string
  centre_id: number
  center: string
  level_id: number
  level: string
  timing_id: number
  timing: string
  onsite_att: number | 0
  online_att: number | 0
  parent_server: number | 0
  adult_dmk: number | 0
  youth_dmk: number | 0
  salvation: number | 0
  note: string | ''
  created_at: string
}

const TngAdditionalInfo = () => {
  const {toasts} = Utilities()
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [datas, setDatas] = useState<TngData[]>([])

  const [loading, setLoading] = useState(false)

  const [centers, setCenters] = useState<Centers[]>([])

  const [time, setTime] = useState<Time[]>([])

  const [timeset, setTimeset] = useState<Time[]>([])

  const [levels, setLevels] = useState<Levels[]>([])

  const {state} = useLocation()

  const {register, handleSubmit, reset, resetField} = useForm<Tng>({})

  const [timingdis, setTimingdis] = useState(false)

  const [mode, setMode] = useState(false)


  useEffect(() => {
    const history = createBrowserHistory()
    history.replace('', null)

    axios
      .get(`${API_URL}center/get-all-centers`)
      .then((res) => {
        setCenters(res.data.data)
      })
      .catch((error) => console.error(`Error:${error}`))

    axios
      .get(`${API_URL}tng-level/get-all-tng-levels`)
      .then((res) => {
        setLevels(res.data.data)
      })
      .catch((error) => console.error(`Error:${error}`))

    axios
      .get(`${API_URL}timing/get-all-level-timings`)
      .then((res) => {
        setTime(res.data.data)
      })
      .catch((error) => console.error(`Error:${error}`))

    if (state != null) {
      setMode(false)
      axios
        .get(`${API_URL}tng/get-tng/${state}`)
        .then((res) => {
          setDatas(res.data.data)
          reset({
            id: res.data.data[0]['id'],
            date: res.data.data[0]['date'],
            level_id: res.data.data[0]['level_id'],
            centre_id: res.data.data[0]['center_id'],
            timing_id: res.data.data[0]['timing_id'],
            parent_server: res.data.data[0]['parent_server'],
            adult_dmk: res.data.data[0]['adult_dmk'],
            youth_dmk: res.data.data[0]['youth_dmk'],
            salvation: res.data.data[0]['salvation'],
          })

          setStartDate(new Date(moment(res.data.data[0]['date']).format()))
        })
        .catch((error) => console.error(`Error:${error}`))
    } else {
      setMode(true)
    }
  }, [])

  const selectservice = (e: any) => {
    var id: number = e
    const data = time.filter((newData) => newData.level_id == id);
    setTimeset(data)
    reset({timing_id: data.length > 0 ? data[0].id : undefined});
    if (data.length == 0) {
      setTimingdis(true);
    } else {
      setTimingdis(false)
    }
  }

  function resetForm() {
    resetField('date')
    resetField('centre_id')
    resetField('level_id')
    resetField('timing_id')
    resetField('parent_server')
    resetField('adult_dmk')
    resetField('youth_dmk')
    resetField('salvation')
  }

  const navigate = useNavigate()
  const onSubmit: SubmitHandler<Tng> = (data) => {
    setLoading(true)

    data.date = moment(startDate).format('YYYY-MM-DD')
    axios
      .post(`${API_URL}tng/add-or-update-additional-info`, data)
      .then((res) => {
        if (res.data.data === true) {
          toasts('success', 'Data Saved Successfully !')
          resetForm()
          setLoading(false)
        } else {
          setLoading(false)
          resetForm()
          toasts('error', res.data.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        resetForm()
        if (state === null) {
          toasts('error', 'Error in adding additional info')
        } else {
          toasts('error', 'Failed to modify additional info')
        }
        console.error(`Error:${error}`)
      });
  }

  function discardEdit() {
    setMode(true)

    if (mode == false) {
      navigate('/module/tng/tngweekly', {state: false})
    }
  }

  return (
    <>
      <ToastContainer theme={'colored'} />
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Data Entry</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form className='form' onSubmit={handleSubmit(onSubmit)}>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Date</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <DatePicker
                        disabled={state != null}
                        dateFormat='dd MMM yyyy'
                        selected={startDate}
                        calendarStartDay={1}
                        onChange={(date) => {
                          setStartDate(date)
                        }}
                        includeDateIntervals={[{start: new Date('1960/01/1'), end: new Date()}]}
                        className='form-control form-control-lg mb-3 mb-lg-0'
                      />

                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Centre</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  {state === null ? (
                    <select
                      required={true}
                      {...register('centre_id')}
                      name='centre_id'
                      className='form-select form-select-lg fw-bold'
                    >
                      <option value=''>Select</option>
                      {centers.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold col-form-label fs-6'>
                        {' '}
                        <span>{datas[0]?.center}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Level</span>
                </label>

                <div className='col-lg-4 fv-row'>
                  {state === null ? (
                    <div className='d-flex align-items-center'>
                      <select
                        required={true}
                        {...register('level_id')}
                        name='level_id'
                        onChange={(e) => selectservice(e.target.value)}
                        className='form-select form-select-lg fw-bold'
                      >
                        <option value=''>Select</option>
                        {levels.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  ) : (
                    <div className='col-lg-12 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold col-form-label fs-6'>
                        {' '}
                        <span>{datas[0]?.level}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                  <span>Time</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  {state === null ? (
                    <select
                      required={true}
                      {...register('timing_id')}
                      name='timing_id'
                      disabled={timingdis}
                      className='form-select form-select-lg fw-bold'
                    >
                      {timeset.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold col-form-label fs-6'>
                        {' '}
                        <span>{datas[0]?.timing}</span>
                      </div>
                    </div>
                  )}

                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Parent Server</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    {...register('parent_server', {
                      valueAsNumber: true,
                    })}
                    name='parent_server'
                    min={0}
                    type='number'
                    className='form-control form-control-lg'
                    placeholder='0'
                  />
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Adult DMK</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    {...register('adult_dmk', {
                      valueAsNumber: true,
                    })}
                    name='adult_dmk'
                    min={0}
                    type='number'
                    className='form-control form-control-lg'
                    placeholder='0'
                  />
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Youth DMK</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    {...register('youth_dmk', {
                      valueAsNumber: true,
                    })}
                    name='youth_dmk'
                    min={0}
                    type='number'
                    className='form-control form-control-lg'
                    placeholder='0'
                  />
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Salvations</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    {...register('salvation', {
                      valueAsNumber: true,
                    })}
                    name='salvation'
                    min={0}
                    type='number'
                    className='form-control form-control-lg'
                    placeholder='0'
                  />
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='reset'
                onClick={discardEdit}
                className='btn btn-default'
                disabled={loading}
              >
                Cancel
              </button>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default TngAdditionalInfo
