/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'
import { IUpdatePassword, updatePassword } from './SettingsModel'
import { useAuth } from '../../../auth'
import axios from 'axios'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Utilities from '../../../../services/Utilities'
const API_URL = process.env.REACT_APP_API_URL


const passwordFormValidationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required('Password is required'),
  newPassword: Yup.string()
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .required('Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})

const Settings: React.FC = () => {
const {toasts}=Utilities();
 const { currentUser } = useAuth()
  const [passwordUpdateData, setPasswordUpdateData] = useState<IUpdatePassword>(updatePassword)
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false)
  const [showPasswordForm, setPasswordForm] = useState<boolean>(false)

  const [loading1, setLoading1] = useState(false)


  const [loading2, setLoading2] = useState(false)

  const formik2 = useFormik<IUpdatePassword>({
    initialValues: {
      ...passwordUpdateData,
    },


    validationSchema: passwordFormValidationSchema,
    onSubmit: (values) => {
        console.log(values)
    
        axios.put(`${API_URL}user/change-password/${currentUser?.id}`, values).then((res) => {
            if (res.data.data === true) {
                setLoading2(false)
                setPasswordForm(false)
                toasts("success", "Password Changed Successfully !");

            }else{
                toasts("error", "Old Password is Wrong !");
               
            }
          }).catch(error => console.error(`Error:${error}`));   
    },
  })

  return (
    <><ToastContainer theme={"colored"} />
    <div className='card mb-5 mb-xl-10'>
          <div
              className='card-header border-0 cursor-pointer'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_account_signin_method'
          >
              <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Account</h3>
              </div>
          </div>

          <div id='kt_account_signin_method' className='collapse show'>
              <div className='card-body border-top p-9'>
                  <div className='d-flex flex-wrap align-items-center'>
                      <div id='kt_signin_email'>
                          <div className='fs-6 fw-bolder mb-1'>Email Address</div>
                          <div className='fw-bold text-gray-600'>{currentUser?.email}</div>
                      </div>


                  </div>

                  <div className='separator separator-dashed my-6'></div>

                  <div className='d-flex flex-wrap align-items-center mb-10'>
                      <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
                          <div className='fs-6 fw-bolder mb-1'>Password</div>
                          <div className='fw-bold text-gray-600'>************</div>
                      </div>

                      <div
                          id='kt_signin_password_edit'
                          className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
                      >
                          <form
                              onSubmit={formik2.handleSubmit}
                              id='kt_signin_change_password'
                              className='form'
                              noValidate
                          >
                              <div className='row mb-1'>
                                  <div className='col-lg-4'>
                                      <div className='fv-row mb-0'>
                                          <label htmlFor='currentpassword' className='form-label fs-6 fw-bolder mb-3'>
                                              Current Password
                                          </label>
                                          <input
                                              type='password'
                                              className='form-control form-control-lg'
                                              id='currentpassword'
                                              {...formik2.getFieldProps('currentPassword')} />
                                          {formik2.touched.currentPassword && formik2.errors.currentPassword && (
                                              <div className='fv-plugins-message-container'>
                                                  <div className='fv-help-block'>{formik2.errors.currentPassword}</div>
                                              </div>
                                          )}
                                      </div>
                                  </div>

                                  <div className='col-lg-4'>
                                      <div className='fv-row mb-0'>
                                          <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                                              New Password
                                          </label>
                                          <input
                                              type='password'
                                              className='form-control form-control-lg'
                                              id='newpassword'
                                              {...formik2.getFieldProps('newPassword')} />
                                          {formik2.touched.newPassword && formik2.errors.newPassword && (
                                              <div className='fv-plugins-message-container'>
                                                  <div className='fv-help-block'>{formik2.errors.newPassword}</div>
                                              </div>
                                          )}
                                      </div>
                                  </div>

                                  <div className='col-lg-4'>
                                      <div className='fv-row mb-0'>
                                          <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                                              Confirm New Password
                                          </label>
                                          <input
                                              type='password'
                                              className='form-control form-control-lg'
                                              id='confirmpassword'
                                              {...formik2.getFieldProps('passwordConfirmation')} />
                                          {formik2.touched.passwordConfirmation && formik2.errors.passwordConfirmation && (
                                              <div className='fv-plugins-message-container'>
                                                  <div className='fv-help-block'>{formik2.errors.passwordConfirmation}</div>
                                              </div>
                                          )}
                                      </div>
                                  </div>
                              </div>

                              <div className='form-text mb-5' style={{ color: 'Red' }}>
                                 
                              </div>

                              <div className='d-flex'>
                                  <button
                                      id='kt_password_submit'
                                      type='submit'
                                      className='btn btn-primary me-2 px-6'
                                  >
                                      {!loading2 && 'Update Password'}
                                      {loading2 && (
                                          <span className='indicator-progress' style={{ display: 'block' }}>
                                              Please wait...{' '}
                                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                          </span>
                                      )}
                                  </button>
                                  <button
                                      onClick={() => {
                        
                                          setPasswordForm(false)
                                      } }
                                      id='kt_password_cancel'
                                      type='button'
                                      className='btn btn-color-gray-400 btn-active-light-primary px-6'
                                  >
                                      Cancel
                                  </button>
                              </div>
                          </form>
                      </div>

                      <div
                          id='kt_signin_password_button'
                          className={'ms-auto ' + (showPasswordForm && 'd-none')}
                      >
                          <button
                              onClick={() => {
                                  setPasswordForm(true)
                              } }
                              className='btn btn-light btn-active-light-primary'
                          >
                              Change Password
                          </button>
                      </div>
                  </div>


              </div>
          </div>
      </div></>
  )
}

export {Settings}
