import axios from 'axios'
import React, {useState, useEffect} from 'react'
import {ToastContainer} from 'react-toastify'
import Utilities from '../../services/Utilities'
import 'react-toastify/dist/ReactToastify.css'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import Moment from 'react-moment'
import {Button, Modal, Spinner} from 'react-bootstrap'
import clsx from 'clsx'
import DatePicker from "react-datepicker";
import queryString from 'query-string';
import './horizontal_scrollbar.css';
import { KTSVG } from '../../../_metronic/helpers'
const API_URL = process.env.REACT_APP_API_URL

const tableContainerStyle: React.CSSProperties = {
  overflowX: 'auto',
  maxHeight: '600px',  // Adjust this height based on your design
  position: 'relative',  /* Make the container a positioning context */
};

// Styling for the sticky header
const stickyHeaderStyle: React.CSSProperties = {
  position: 'sticky' as 'sticky',
  top: 0,  /* Stick to the top */
  backgroundColor: '#fff',  /* Match the background color of the table */
};

type Event = {
  id: number
  name: string
  category_id: number
  category: string
  event_id: number
  event: string
  eventsub_id: number
  eventsub: string
  freq_id: number
  freq: string
  date_start: string
  date_end: string
  mode_id: number
  mode: string
  region_id: number
  region: string
  thrust_id: number
  thrust: string
  location: string
  country: string
  mo: string
  speaker: string
  regist_att: number
  volun: number
  onsite_att: number
  online_att: number
  onsite_salv: number
  online_salv: number
  summary: string
  created_at: string
  created_by: string
  updated_at: string
}

type Pages = {
  no: string
  page: string
  prev: string
  next: string
}

type SelectOption = {
  id: string;
  name: string;
}

type FilterInputs = {
  page: string;
  from_date?: string;
  to_date?: string;
  event_type_id?: string;
  region_id?: string;
  thrust_id?: string;
  mo: boolean;
}

function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const EventList = () => {
  const [data, setData] = useState<Event[]>([])
  const [prev, setPrev] = useState<string>('')
  const [next, setNext] = useState<string>('')
  const [pages, setPages] = useState<Pages[]>([])
  const [loading, setLoading] = useState(false)
  const {toasts} = Utilities()
  const {state} = useLocation()
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 365);
  const [fromDate, setFromDate] = useState<Date>(defaultStartDate);
  const [toDate, setToDate] = useState<Date>(new Date());
  const [currPageNo, setCurrPageNo] = useState<string>();
  const [optSmModal, setOptSmModal] = useState(false)
  const toggleShowSm = () => setOptSmModal(!optSmModal)
  const [eventTypeOptions, setEventTypeOptions] = useState<Array<SelectOption>>();
  const [regionOptions, setRegionOptions] = useState<Array<SelectOption>>();
  const [thrustOptions, setThrustOptions] = useState<Array<SelectOption>>();
  const [selectedEventType, setSelectedEventType] = useState<string>();
  const [selectedRegion, setSelectedRegion] = useState<string>();
  const [selectedThrust, setSelectedThrust] = useState<string>();
  const [selectedMoOnly, setSelectedMoOnly] = useState<boolean>(false);
  const handleSelectedMoOnlyChange = () => {
    setSelectedMoOnly(!selectedMoOnly);
  };
  const [exportFromDate, setExportFromDate] = useState<Date>(defaultStartDate);
  const [exportToDate, setExportToDate] = useState<Date>(new Date());
  const [exportEventType, setExportEventType] = useState<string>();
  const [exportRegion, setExportRegion] = useState<string>();
  const [exportThrust, setExportThrust] = useState<string>();
  const [exportMoOnly, setExportMoOnly] = useState<boolean>(false);
  const handleExportMoOnlyChange = () => {
    setExportMoOnly(!exportMoOnly);
  };
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const toggleShowExport = () => setIsExportModalOpen(!isExportModalOpen);

  useEffect(() => {
    const history = createBrowserHistory()
    history.replace('', null)

    if (state) {
      toasts('success', 'Data Updated Successfully !')
    }
    getData();
    getEventOptions();
  }, [currPageNo])

  const getEventOptions = () => {
    axios
    .get(`${API_URL}event/get-event-options`)
    .then((res) => {
      setRegionOptions(res.data.data.region);
      setEventTypeOptions(res.data.data.event);
      setThrustOptions(res.data.data.thrust);
    })
    .catch((error) => console.error(`Error:${error}`))
  }

  const getData = () => {
    const inputs: FilterInputs = {
      page: currPageNo || '1',
      mo: selectedMoOnly,
    }
    if (fromDate) {inputs.from_date = formatDate(fromDate);}
    if (toDate) {inputs.to_date = formatDate(toDate);}
    if (selectedEventType && selectedEventType != 'All') {inputs.event_type_id = selectedEventType;}
    if (selectedRegion && selectedRegion != 'All') {inputs.region_id = selectedRegion;}
    if (selectedThrust && selectedThrust != 'All') {inputs.thrust_id = selectedThrust;}
    const query = queryString.stringify(inputs);
    axios
      .get(`${API_URL}event/get-all-event?${query}`)
      .then((res) => {
        setPrev(res.data.data.prev)
        setNext(res.data.data.next)
        setData(res.data.data.data)
        setPages(res.data.data.pages)
      })
      .catch((error) => console.error(`Error:${error}`))
  }

  const updatePage = (no: string) => {
    setCurrPageNo(no);
  }

  const navigate = useNavigate()
  const gotoEdit = (id: number) => {
    navigate('/module/event/eventregistration', {state: id})
  }
  const [id, setId] = useState(0)
  const gotoDel = (id: number) => {
    setId(id)
    toggleShowSm()
  }

  const deleteData = () => {
    let data = {
      id: id,
    }
    setLoading(true)

    axios
      .delete(`${API_URL}event/delete-event`, {data})
      .then((res) => {
        if (res.data.data === true) {
          toasts('success', 'Data Deleted Successfully !')
          getData()
          setLoading(false)
          toggleShowSm()
        } else {
          setLoading(false)
          toggleShowSm()
          toasts('error', 'Cannot delete data, You are not the owner !')
        }
      })
      .catch((error) => console.error(`Error:${error}`))
  }

  const exportExcel = () => {
    setLoading(true)
    const inputs: FilterInputs = {
      page: currPageNo || '1',
      mo: exportMoOnly,
    }
    if (exportFromDate) {inputs.from_date = formatDate(exportFromDate);}
    if (exportToDate) {inputs.to_date = formatDate(exportToDate);}
    if (exportEventType && exportEventType != 'All') {inputs.event_type_id = exportEventType;}
    if (exportRegion && exportRegion != 'All') {inputs.region_id = exportRegion;}
    if (exportThrust && exportThrust != 'All') {inputs.thrust_id = exportThrust;}
    const query = queryString.stringify(inputs);
    axios
      .get(`${API_URL}event/export-events-excel?${query}`, {
        responseType: 'arraybuffer', // Set responseType to 'arraybuffer' for binary data
      })
      .then((res) => {
        if (res.data) {
          toasts('success', 'Exported Successfully!')

          // Assuming the response contains the file data
          const blob = new Blob([res.data], { type: res.headers['content-type'] });

          // Create a URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Trigger a download
          const a = document.createElement('a');
          a.href = url;
          a.download = 'Exported Events.xlsx'; // Provide a file name with the appropriate extension
          document.body.appendChild(a);
          a.click();

          // Cleanup
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          setLoading(false)
          toggleShowExport()
        } else {
          setLoading(false)
          toggleShowExport()
          toasts('error', 'Unable to Export Events to Excel')
        }
      })
      .catch((error) => console.error(`Error:${error}`))
  }

  const ExportModal = () => {
    return (
      <>
        <Modal show={isExportModalOpen} onHide={toggleShowExport} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Export To Excel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className='mb-2'>
                <h4 className='fw-bold text-muted mx-2'>From</h4>
                <DatePicker className="form-control w-125px me-2 col-ms-12"
                    dateFormat="yyyy-MM-dd" selected={exportFromDate} onChange={(date) => setExportFromDate(date || new Date())} name="fromDate" />
            </label>
            <label className='mb-2'>
                <h4 className='fw-bold text-muted mx-2'>To</h4>
                <DatePicker className="form-control w-125px me-2 col-ms-12"
                    dateFormat="yyyy-MM-dd" selected={exportToDate} onChange={(date) => setExportToDate(date || new Date())} name="toDate" />
            </label>
            <label className='mx-2'>
                <h4 className='fw-bold text-muted mx-2'>Event Type</h4>
              <select onChange={(e) => setExportEventType(e.target.value)}
                  className='form-select w-150px fw-bold me-2'
                  data-kt-select2='true'
                  data-placeholder='Select Centre'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
              >
                <option key={0} value={undefined}>All</option>
                  {eventTypeOptions?.map((item, index) => {
                          return (
                              <option key={index+1} value={item.id}>{item.name}</option>
                          );
                      })}
              </select>
            </label>
            <label className='mx-2'>
                <h4 className='fw-bold text-muted mx-2'>Region</h4>
              <select onChange={(e) => setExportRegion(e.target.value)}
                  className='form-select w-150px fw-bold me-2'
                  data-kt-select2='true'
                  data-placeholder='Select Centre'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
              >
                <option key={0} value={undefined}>All</option>
                  {regionOptions?.map((item, index) => {
                          return (
                              <option key={index+1} value={item.id}>{item.name}</option>
                          );
                      })}
              </select>
            </label>
            <label className='mx-2'>
                <h4 className='fw-bold text-muted mx-2'>Thrust</h4>
              <select onChange={(e) => setExportThrust(e.target.value)}
                  className='form-select w-150px fw-bold me-2'
                  data-kt-select2='true'
                  data-placeholder='Select Centre'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
              >
                <option key={0} value={undefined}>All</option>
                  {thrustOptions?.map((item, index) => {
                          return (
                              <option key={index+1} value={item.id}>{item.name}</option>
                          );
                      })}
              </select>
            </label>
            <label className='mx-2'>
                <h4 className='fw-bold text-muted mx-2'>MO</h4>
                  <input 
                  checked={exportMoOnly}
                  onChange={handleExportMoOnlyChange}
                  type='checkbox' 
                  className='fw-bold text-muted mx-3 my-3' />
            </label>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-success' onClick={() => exportExcel()} disabled={loading}>
              {!loading && 'Export'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Button variant='secondary' onClick={toggleShowExport}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const ModalConfirm = () => {
    return (
      <>
        <Modal show={optSmModal} onHide={toggleShowSm} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure want to delete the data ?</Modal.Body>
          <Modal.Footer>
            <button className='btn btn-danger' onClick={() => deleteData()} disabled={loading}>
              {!loading && 'Confirm'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Button variant='secondary' onClick={toggleShowSm}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  const Paginations = () => {
    return (
      <div className='row mt-10'>
        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
          <div id='kt_table_users_paginate'>
            <ul className='pagination'>
              <li>
                <a
                  onClick={() => updatePage(prev)}
                  className={clsx('page-link me-5')}
                  style={{cursor: 'pointer'}}
                >
                  {' '}
                  Previous
                </a>
              </li>
              {pages
                ?.map((link) => {
                  return {...link, label: link.no}
                })
                .map((link) => (
                  <li
                    key={link.label}
                    className={clsx('page-item', {
                      active: link.page === link.no,
                    })}
                  >
                    <a
                      onClick={() => updatePage(link.no)}
                      className={clsx('page-link')}
                      style={{cursor: 'pointer'}}
                    >
                      {' '}
                      {link.no}
                    </a>
                  </li>
                ))}

              <li>
                <a
                  onClick={() => updatePage(next)}
                  className={clsx('page-link me-15')}
                  style={{cursor: 'pointer'}}
                >
                  {' '}
                  Next
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <ToastContainer theme={'colored'} />
      <ModalConfirm />
      <ExportModal />
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Events</h3>
          </div>
          <label>
              <button
                  type='button'
                  className='btn btn-light-primary my-6'
                onClick={toggleShowExport}
              >
                  <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
                  Export
              </button>
          </label>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body border-top p-9'>
            {/* begin::Header */}
            {/* <div className='card-header border-0'>
              <h3 className='card-title align-items-start flex-column'></h3>
            </div> */}
              <div className='card-toolbar'>
                <div className='justify-content-start mx-9'>
                    <label className='mb-2'>
                        <h4 className='fw-bold text-muted mx-2'>From</h4>
                        <DatePicker className="form-control w-125px me-2 col-ms-12"
                            dateFormat="yyyy-MM-dd" selected={fromDate} onChange={(date) => setFromDate(date || new Date())} name="fromDate" />
                    </label>
                    <label className='mb-2'>
                        <h4 className='fw-bold text-muted mx-2'>To</h4>
                        <DatePicker className="form-control w-125px me-2 col-ms-12"
                            dateFormat="yyyy-MM-dd" selected={toDate} onChange={(date) => setToDate(date || new Date())} name="toDate" />
                    </label>
                    <label className='mx-2'>
                        <h4 className='fw-bold text-muted mx-2'>Event Type</h4>
                      <select onChange={(e) => setSelectedEventType(e.target.value)}
                          className='form-select w-150px fw-bold me-2'
                          data-kt-select2='true'
                          data-placeholder='Select Centre'
                          data-allow-clear='true'
                          data-kt-user-table-filter='role'
                          data-hide-search='true'
                      >
                        <option key={0} value={undefined}>All</option>
                          {eventTypeOptions?.map((item, index) => {
                                  return (
                                      <option key={index+1} value={item.id}>{item.name}</option>
                                  );
                              })}
                      </select>
                    </label>
                    <label className='mx-2'>
                        <h4 className='fw-bold text-muted mx-2'>Region</h4>
                      <select onChange={(e) => setSelectedRegion(e.target.value)}
                          className='form-select w-150px fw-bold me-2'
                          data-kt-select2='true'
                          data-placeholder='Select Centre'
                          data-allow-clear='true'
                          data-kt-user-table-filter='role'
                          data-hide-search='true'
                      >
                        <option key={0} value={undefined}>All</option>
                          {regionOptions?.map((item, index) => {
                                  return (
                                      <option key={index+1} value={item.id}>{item.name}</option>
                                  );
                              })}
                      </select>
                    </label>
                    <label className='mx-2'>
                        <h4 className='fw-bold text-muted mx-2'>Thrust</h4>
                      <select onChange={(e) => setSelectedThrust(e.target.value)}
                          className='form-select w-150px fw-bold me-2'
                          data-kt-select2='true'
                          data-placeholder='Select Centre'
                          data-allow-clear='true'
                          data-kt-user-table-filter='role'
                          data-hide-search='true'
                      >
                        <option key={0} value={undefined}>All</option>
                          {thrustOptions?.map((item, index) => {
                                  return (
                                      <option key={index+1} value={item.id}>{item.name}</option>
                                  );
                              })}
                      </select>
                    </label>
                    <label className='mx-2'>
                      <h4 className='fw-bold text-muted mx-2'>MO</h4>
                      <input 
                      checked={selectedMoOnly}
                      onChange={handleSelectedMoOnlyChange}
                      type='checkbox' 
                      className='fw-bold text-muted mx-3 my-3' />
                    </label>
                    <label className='mx-2'>
                      <Button
                      variant="primary"
                      onClick={getData}
                      disabled={loading}
                      >
                      {loading ? (
                          <>
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                          {' Updating...'}
                          </>
                      ) : (
                          'Update'
                      )}
                      </Button>
                    </label>
                    </div>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive table-container' style={tableContainerStyle}>
                  {/* begin::Table */}
                  <table className='align-middle table table-row-bordered gy-7 gs-7'>
                    {/* begin::Table head */}
                    <thead style={stickyHeaderStyle}>
                      <tr className='fw-bold bg-light text-muted fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                        <th className='ps-4 min-w-100px rounded-start'>Event Name</th>
                        <th className='min-w-125px'>Discipleship Process</th>
                        <th className='min-w-125px'>Event Type</th>
                        <th className='min-w-125px'>Frequency</th>
                        <th className='min-w-100px'>Date</th>
                        <th className='min-w-100px'>Mode</th>
                        <th className='min-w-100px'>Region</th>
                        <th className='min-w-100px'>Thrust</th>
                        <th className='min-w-100px'>Location</th>
                        <th className='min-w-100px'>Country</th>
                        <th className='min-w-100px'>MO</th>
                        <th className='min-w-100px'>Speaker</th>
                        <th className='min-w-100px'>Registered Attendee</th>
                        <th className='min-w-100px'>Volounteers</th>
                        <th className='min-w-100px'>Onsite Attendee</th>
                        <th className='min-w-100px'>Online Attendee</th>
                        <th className='min-w-100px'>Onsite Salvations</th>
                        <th className='min-w-100px'>Online Salvations</th>
                        <th className='min-w-100px'>Created By</th>
                        <th className='min-w-100px'>Updated At</th>
                        <th className='min-w-100px'>Created At</th>
                        <th className='min-w-200px text-end rounded-end'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.category}</td>
                            <td>
                              {item.event}-{item.eventsub}
                            </td>
                            <td>{item.freq}</td>
                            <td>{item.date_start}</td>
                            <td>{item.mode}</td>
                            <td>{item.region}</td>
                            <td>{item.thrust}</td>
                            <td>{item.location}</td>
                            <td>{item.country}</td>
                            <td>{item.mo}</td>
                            <td>{item.speaker}</td>
                            <td>{item.regist_att}</td>
                            <td>{item.volun}</td>
                            <td>{item.onsite_att}</td>
                            <td>{item.online_att}</td>
                            <td>{item.onsite_salv}</td>
                            <td>{item.online_salv}</td>
                            <td>{item.created_by}</td>
                            <td>
                              {item.updated_at != null && (
                                <Moment format='DD-MM-YYYY HH:mm'>{item.updated_at}</Moment>
                              )}
                            </td>
                            <td>
                              <Moment format='DD-MM-YYYY HH:mm'>{item.created_at}</Moment>{' '}
                            </td>
                            <td className='text-end'>
                              <a
                                onClick={() => gotoEdit(item.id)}
                                className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                              >
                                Edit
                              </a>
                              <a
                                onClick={() => gotoDel(item.id)}
                                className='btn btn-bg-danger btn-color-white btn-active-color-primary btn-sm px-4'
                              >
                                Delete
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
              </div>
              {/* end::Table container */}
              <Paginations />
            </div>
            {/* begin::Body */}
          </div>
        </div>
    </>
  )
}

export default EventList
