import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { TngHeader } from './TngHeader'
import TngRegistration from './TngRegistration'
import TngWeekly from './TngWeekly'
import TngAdditionalInfo from './TngAdditionalInfo'


const accountBreadCrumbs: Array<PageLink> = [
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

type Props = {
  read: boolean
  write: boolean
}

const TngPage: React.FC<Props> = ({ read, write }) => {
  return (
    <Routes>
      <Route
        element={
          <>
            <TngHeader />
            <Outlet />
          </>
        }
      >


        {write ? (
          <Route
            path='tngregistration'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>TNG Data Entry</PageTitle>
                <TngRegistration />

              </>
            }
          />

        ) : (

          <Route path='*' element={<Navigate to='/error/404' />} />
        )}

        {write ? (
          <Route
            path='tngaddlinfo'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>TNG Additional Info Entry</PageTitle>
                <TngAdditionalInfo />

              </>
            }
          />
        ) : (

          <Route path='*' element={<Navigate to='/error/404' />} />
        )}


        {read ? (
          <Route
            path='tngweekly'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>TNG Attendance</PageTitle>
                <TngWeekly />

              </>
            }
          />

        ) : (

          <Route path='*' element={<Navigate to='/error/404' />} />
        )}


        <Route index element={<Navigate to='/module/workship/registration' />} />
      </Route>
    </Routes>
  )
}

export default TngPage
