import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import Moment from 'react-moment'
import clsx from 'clsx';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Carousel } from 'react-bootstrap';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL


type Data = {
    id: number;
    name: string;
    loc: string;
    country: string;
    speaker: string;
    start: string;
    end: string;
    volun: number;
    onsite_att: number;
    online_att: number;
    onsite_salv: number;
    mo: string;
    category: string;
    summary: string;
    mode: string
    pth: []
}


type Filter = {
    id: number;
    name: string;
}


const ListEvent = () => {

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [select, setSelect] = useState<number>(0)
    const [filter, setFilter] = useState<Filter[]>([]);
    const [list, setList] = useState<Data[]>([]);
    const [pageNo, setPageno] = useState<string>('1');

    const [start, setStart] = useState<Date | null>(new Date(new Date().getFullYear(), 0, 1));
    const [end, setEnd] = useState<Date | null>(new Date());


    const [value, setValue] = useState<number>(0);
    const [prev, setPrev] = useState<string>('');
    const [next, setNext] = useState<string>('');
    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }

    useEffect(() => {

        axios.get(`${API_URL}category/get-all-categories`).then((res) => {
            setFilter(res.data.data)
        }).catch(error => console.error(`Error:${error}`));

        OpenData("1")

    }, []);



    const updatePage = (no: string) => {
        OpenData(no)
    }


    const ShowData = () => {
        OpenData("1")
    }

    const navigate = useNavigate();
    const gotoEdit = (id: number) => {
        navigate('/module/eventreport/eventdetail', { state: id });

    }

    function OpenData(no: string | undefined) {





        let varstar = moment(start).format('YYYY-MM-DD');
        let varend = moment(end).format('YYYY-MM-DD');



        let url

        if (varstar === null && select === 0) {
            url = `event/get-filtered-event-list?start=${varstar}&end=${varend}&page=${pageNo}&filter=4&id=0`
        }
        else if (select > 0 && varstar === null) {
            url = `event/get-filtered-event-list?start=${varstar}&end=${varend}&page=${pageNo}&filter=1&id=${select}`
        }
        else if (varstar != null && select === 0) {
            url = `event/get-filtered-event-list?start=${varstar}&end=${varend}&page=${pageNo}&filter=2&id=${select}`
        }
        else if (varstar != null && select > 0) {
            url = `event/get-filtered-event-list?start=${varstar}&end=${varend}&page=${pageNo}&filter=3&id=${select}`
        }


        axios.get(`${API_URL}${url}`).then((res) => {
            setList(res.data.data.data)
            setPrev(res.data.data.prev)
            setNext(res.data.data.next)
            setPageno(no || '1');
        }).catch(error => console.error(`Error:${error}`));
    }



    return (
        <div>
            <KTCard>
                <KTCardBody>


                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end me-3'>

                            <div className='d-flex align-items-left position-relative my-1'>



                                <div className='d-flex justify-content-end me-3'>



                                    <div className='d-flex justify-content-end me-3'>
                                        <DatePicker className="form-control" calendarStartDay={1}
                                            dateFormat="dd MMM yyyy" selected={start} onChange={(date) => setStart(date)} name="date" />
                                    </div>


                                    <div className='d-flex justify-content-end me-3'>
                                        <DatePicker className="form-control" calendarStartDay={1}
                                            dateFormat="dd MMM yyyy" selected={end} onChange={(date) => setEnd(date)} name="date" />
                                    </div>

                                </div>


                                <div className='d-flex justify-content-end'>


                                    <select onChange={(e) => setSelect(Number(e.target.value))}
                                        className='form-select fw-bolder me-2' style={{ width: "150px" }}
                                        data-kt-select2='true'
                                        data-placeholder='Select option'
                                        data-allow-clear='true'
                                        data-hide-search='true'
                                    >
                                        <option value="0" disabled>Category</option>
                                        {filter.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.name}</option>

                                            )
                                        })}
                                    </select>


                                    <button onClick={() => ShowData()}
                                        type='button'
                                        className='btn btn-light-primary me-3'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                    >
                                        Update
                                    </button>

                                </div>
                            </div>



                        </div>
                    </div>

                </KTCardBody>

            </KTCard>

            <br />

            {list.length > 0 ? (

                list.map((item, index) => {
                    return (<KTCard key={index} className='mb-5'>

                        <div className='card-header border-0 pt-6'>
                            <div className='card-toolbar'><h1>{item.name}</h1></div>
                        </div>
                        <KTCardBody>

                            <div className='row'>
                                <div className='col-lg-4 col-sm-12'>

                                    {item.pth.length > 0 ? (
                                        <Carousel slide={false} interval={null}>
                                            {item.pth.map((value, index) => {

                                                return (
                                                    <Carousel.Item key={index}>
                                                        <img
                                                            className="mx-auto d-block img-fluid"
                                                            src={value["a"]}
                                                            alt="First slide" />

                                                    </Carousel.Item>
                                                );
                                            })}




                                        </Carousel>

                                    ) : (


                                        <img
                                            className="mx-auto d-block h-200px img-fluid"
                                            src={toAbsoluteUrl('/media/misc/image.png')}
                                            alt="First slide" />



                                    )}

                                </div>


                                <div className='col-lg-8 col-sm-12'>

                                    <div className='row'>

                                        <div dangerouslySetInnerHTML={{ __html: item.summary }} />


                                    </div>

                                    <div className='row'>
                                        <div className='col-12'>

                                            <table className="w-100">

                                                <tbody>
                                                    <tr>
                                                        <td className='w-200px'>Date Held</td>
                                                        <td><b>{item.start} - {item.end}</b></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Location</td>
                                                        <td><b>{item.loc} | {item.country}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ministry Organizer</td>
                                                        <td><b>{item.mo}</b></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Speaker/Host</td>
                                                        <td><b>{item.speaker}</b></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Category</td>
                                                        <td><b>{item.category}</b></td>
                                                    </tr>

                                                    <tr>
                                                        {item.volun > 0 && (
                                                            <><td>Total Volunteer/s</td><td><b>{item.volun}</b></td></>

                                                        )}

                                                    </tr>

                                                    {item.mode == 'Onsite' && (

                                                        <tr>

                                                            {item.onsite_att > 0 && (
                                                                <><td>Total Onsite Attendee/s</td><td><b>{item.onsite_att}</b></td></>

                                                            )}
                                                        </tr>

                                                    )}


                                                    {item.mode == 'Online' && (

                                                        <tr>

                                                            {item.online_att > 0 && (
                                                                <><td>Total Online Attendee/s</td><td><b>{item.online_att}</b></td></>

                                                            )}
                                                        </tr>

                                                    )}



                                                    {item.mode == 'Hybrid' && (
                                                        <>

                                                            <tr>

                                                                {item.onsite_att > 0 && (
                                                                    <><td>Total Onsite Attendee/s</td><td><b>{item.onsite_att}</b></td></>

                                                                )}
                                                            </tr>

                                                            <tr>

                                                                {item.online_att > 0 && (
                                                                    <><td>Total Online Attendee/s</td><td><b>{item.online_att}</b></td></>

                                                                )}
                                                            </tr>

                                                        </>

                                                    )}
                                                    <tr>
                                                        {item.onsite_salv > 0 && (
                                                            <><td>Total Onsite Salvation/s</td><td><b>{item.onsite_salv}</b></td></>

                                                        )}
                                                    </tr>

                                                    <tr style={{ height: "20px" }}>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>


                                                    <tr>
                                                        <td colSpan={2}>

                                                            <button className='w-100 btn btn-default' onClick={() => gotoEdit(item.id)}
                                                                style={{ backgroundColor: "#C78741", color: "white" }} type="button">Read more...</button>

                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </KTCardBody>
                    </KTCard>
                    );
                })

            ) : (

                <div className='mb-5 d-flex justify-content-center'>No Event found</div>
            )}


            <KTCard>
                <div className='row mt-5 mb-5'>
                    <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                    <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                        <div id='kt_table_users_paginate'>
                            <ul className='pagination'>
                                <li>
                                    <a onClick={() => updatePage(prev)}
                                        className={clsx('page-link me-5', { active: prev != null })}
                                        style={{ cursor: 'pointer' }}
                                    > Previous</a>
                                </li>



                                <li>
                                    <a onClick={() => updatePage(next)}
                                        className={clsx('page-link me-15', { active: next != null })}
                                        style={{ cursor: 'pointer' }}
                                    > Next</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </KTCard>

        </div>
    )
}

export default ListEvent