import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { KTCard, KTCardBody } from '../../../../_metronic/helpers';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactApexChart from 'react-apexcharts';
import Select from 'react-select';
import Button from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/esm/Spinner';

const API_URL = process.env.REACT_APP_API_URL



export interface IState {
    apiurl: string;
    datarecords: any[];
    datacolumns: any[];
}


const CombinedAverage = () => {
    const [loading, setLoading] = useState(false);

    const [start, setStart] = useState<Date | null>(new Date());

    const [apiData, setApiData] = useState<[]>([]);

    const [datatotal, setDatatotal] = useState([]);
    const [columns, setColumns] = useState<Array<{'a': string}>>([])



    const [tablech, setTablech] = useState<boolean>(true);

    const monthNames: string[] = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];
    
    const tableTotal: number[] = new Array(12).fill(0);
    const [centres, setCentres] = useState([]);
    const [selectedCentre, setSelectedCentre] = useState<string>('Bukit Panjang');
    const [selectedServices, setSelectedServices] = useState<Array<string>>([]);
    const [serviceOptions, setServiceOptions] = useState<Array<any>>([]);
    const placeholderText = selectedServices.length > 0 ? `${selectedServices.length} option(s) selected` : 'Select options';
    useEffect(() => {

        applyFilter();

    }, []);

    const handleSelectedServiceChange = (selectedOptions: any) => {
        setSelectedServices(selectedOptions.map((option: any) => option.value));
    };

    const applyFilter = () => {
        let year = start?.getFullYear()
        axios.get(`${API_URL}center/get-all-centers`).then((res) => {
            let result = res.data.data;
            let resultCentres = result.map((item: any) => {
                return item.name;
            })
            setCentres(resultCentres);
            }).catch(error => console.error(`Error:${error}`));
        
        let filters;
        if (selectedCentre) {
            filters = `year=${year}&centre=${selectedCentre}`;
        } else {
            filters = `year=${year}`;
        }
        setLoading(true);
        axios.get(`${API_URL}combined-report/get-combined-monthly-average-attendance-breakdown?${filters}`).then((res) => {
                setColumns(res.data.headers);
                setSelectedServices(res.data.headers.map((item: {a: string}) => item.a));
                setServiceOptions(res.data.headers.map((item: {a: string}) => {return {value: item.a, label: item.a}}))
                setDatatotal(res.data.data);
                setTablech(false)
                setApiData(res.data.data);
                setLoading(false);
        }).catch(error => {
            setLoading(false);
            console.error(`Error:${error}`);
        });
        
    }

    const tableChartOptions = {
        chart: {
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: "15px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: "bold",
                colors: ["black"]
              },
            formatter: function (val: number) {
            const num: number = val > 0 ? Math.round(val * 100) / 100 : 0
            return num.toString();
            },

        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: monthNames,
        },
        fill: {
            opacity: 1
        },
        yaxis: {
            labels: {
              formatter: function (val: number) {
                const num: number = val > 0 ? Math.round(val * 100) / 100 : 0
                return num.toString();
              },
            },
          },
    };

    const Tables = () => {

        return (

            <table
                id='kt_table_users'
                className='align-middle table table-row-bordered gy-7 gs-7'>
                <thead className='fw-bold bg-light text-muted fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                    <tr>
                        <th key={0}>Month</th>
                        {monthNames.map((item, x) => {
                            return (
                                <th key={x+1}>{item}</th>
                            )

                        })}
                    </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold'>
                    {columns.map((columnName, n) => {
                        if (selectedServices.includes(columnName['a'])) {
                            return (
                                <tr key={n}>
                                    <td key={0}>{columnName['a']}</td>
                                    {monthNames.map((item, index) => {
                                        tableTotal[index] += parseFloat(datatotal[index][columnName['a']]);
                                        return (
                                            <td key={index+n}>{datatotal[index][columnName['a']] || 0}</td>
                                        ) 
                                    })}
                                </tr>
                            );
                        }
                    })}
                    <tr key='total'>
                        <td className='text-black fw-bold'>Total</td>
                        {tableTotal.map((item, index) => {
                            return (
                                <td key={'total-' + index} className='fw-bold text-black'>{Math.round(item * 100) / 100 || 0}</td>
                            ) 
                        })}
                    </tr>
                </tbody>
            </table>
        )

    }

    return (
        <div>
            <div className='card mb-5 mb-xl-10'>
                <KTCard>
                    <div className='card-header border-0 pt-6'>
                        <div className='card-title mx-2 p-0 mb-6'>
                            <h3 >Average Worship Attendance and Average TNG Attendance Onsite</h3>
                        </div>
                        <div className='card-toolbar'>
                            <div className=' justify-content-end'>
                                <label className='mx-2'>
                                    <h4 className='fw-bold text-muted mx-2'>Year</h4>
                                    <DatePicker className="form-control w-250px me-2 col-ms-12"
                                        showYearPicker
                                        dateFormat="yyyy" selected={start} onChange={(date) => setStart(date)} name="date" />
                                </label>
                                <label className='mx-2'>
                                    <h4 className='fw-bold text-muted mx-2'>Centre</h4>
                                <select onChange={(e) => setSelectedCentre(e.target.value)}
                                    className='form-select fw-bolder me-2'
                                    data-kt-select2='true'
                                    data-placeholder='Select option'
                                    data-allow-clear='true'
                                    data-kt-user-table-filter='role'
                                    data-hide-search='true'
                                >
                                    {centres.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            );
                                        })}
                                </select>
                                </label>
                                <Button
                                variant="primary"
                                onClick={applyFilter}
                                disabled={loading}
                                >
                                {loading ? (
                                    <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    {' Updating...'}
                                    </>
                                ) : (
                                    'Update'
                                )}
                                </Button>
                                <br></br>
                                <label className='mx-2 mt-2'>
                                    <h4 className='fw-bold text-muted mx-2'>Service / Tng Level</h4>
                                    <Select
                                        isMulti
                                        value={selectedServices.map((value) => serviceOptions.find((option) => option?.value == value))}
                                        options={serviceOptions}
                                        onChange={handleSelectedServiceChange}
                                        placeholder={placeholderText}
                                    />
                                </label>
                            </div>
                        </div>

                    </div>

                    <KTCardBody className='py-4'>
                        <div className='table-responsive'>
                            <Tables />
                        </div>
                        <ReactApexChart options={tableChartOptions} series={[{name: 'Total', data: tableTotal}]} type="bar" height={350} />
                    </KTCardBody>
                </KTCard>
            </div>

        </div>

    )
}

export default CombinedAverage; 