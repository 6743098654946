import axios from 'axios'
import React, {useState, useEffect, useMemo} from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'
import {ToastContainer} from 'react-toastify'
import {number, string} from 'yup'
import {KTSVG} from '../../../_metronic/helpers'
import Utilities from '../../services/Utilities'
import 'react-toastify/dist/ReactToastify.css'
import {useLocation, useNavigate} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import Moment from 'react-moment'
import countryList from 'react-select-country-list'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Select from 'react-select'
import ImageUploading, {ImageListType} from 'react-images-uploading'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import {FALSE} from 'sass'
const API_URL = process.env.REACT_APP_API_URL

type Datasystem = {
  id: number
  name: string
}

type Dataeventsub = {
  id: number
  event_type_id: number
  name: string
}

type Country = {
  id: number
  name: string
}

type Event = {
  id: number
  name: string
  category_id: number
  event_id: number
  eventsub_id: number
  mo_event: boolean
  freq_id: number
  date_start: string
  date_end: string
  mode_id: number
  region_id: number
  thrust_id: number
  location: string
  country: string
  mo: string
  speaker: string
  regist_att: number
  volun: number
  onsite_att: number
  online_att: number
  onsite_salv: number
  online_salv: number
  summary: string
  photos: string
  check: string
  bloob: []
  created_by: string
}

type EventData = {
  id: number
  name: string
  category_id: number
  category: string
  event_id: number
  event: string
  eventsub_id: number
  eventsub: string
  freq_id: number
  freq: string
  date_start: string
  date_end: string
  mode_id: number
  mode: string
  region_id: number
  region: string
  thrust_id: number
  thrust: string
  location: string
  country: string
  mo: string
  speaker: string
  regist_att: number
  volun: number
  onsite_att: number
  online_att: number
  onsite_salv: number
  online_salv: number
  summary: string
}

type TSelectOption = {
  value: string
  label: string
}

const EventRegistration = () => {
  const {toasts} = Utilities()
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [endDate, setEndDate] = useState<Date | null>(new Date())
  const [datas, setDatas] = useState<EventData[]>([])

  const options = useMemo(() => countryList().getData(), [])

  const [valucon, setValuecon] = useState<Pick<TSelectOption, 'value'>>()

  const [loading, setLoading] = useState(false)

  const [category, setCategory] = useState<Datasystem[]>([])
  const [event, setEvent] = useState<Datasystem[]>([])
  const [eventsub, setEventsub] = useState<Dataeventsub[]>([])
  const [eventsubset, setEventsubset] = useState<Dataeventsub[]>([])
  const [freq, setFreq] = useState<Datasystem[]>([])
  const [mode, setMode] = useState<Datasystem[]>([])
  const [region, setRegion] = useState<Datasystem[]>([])
  const [thrust, setThrust] = useState<Datasystem[]>([])

  const [mdevent, setMdevent] = useState(false)
  const [mdeventsub, setMdeventsub] = useState(false)
  const [mdcategory, setMdcategory] = useState(false)
  const [mdfreq, setMdfreq] = useState(false)
  const [mdmode, setMdmode] = useState(false)
  const [mdregion, setMdregion] = useState(false)
  const [mdthrust, setMdthrust] = useState(false)
  const [mdcountry, setMdcountry] = useState(false)
  const [mdonline, setMdonline] = useState<boolean>(false)
  const [mdmission, setMdmission] = useState<boolean>(true)
  const [mdselecteventsub, setMdselecteventsub] = useState<boolean>(true)

  const [images, setImages] = useState([] as any)
  const [imageURLS, setImageURLs] = useState([])

  const {state} = useLocation()

  const {register, handleSubmit, reset, resetField, getValues, setValue} = useForm<Event>({})

  const [modeinsert, setModeInsert] = useState(false)

  useEffect(() => {
    const history = createBrowserHistory()
    history.replace('', null)

    axios
      .get(`${API_URL}event/get-event-options`)
      .then((res) => {
        setCategory(res.data.data['category'])
        setEvent(res.data.data['event'])
        setEventsub(res.data.data['eventsub'])
        setFreq(res.data.data['freq'])
        setMode(res.data.data['mode'])
        setRegion(res.data.data['region'])
        setThrust(res.data.data['thrust'])
      })
      .catch((error) => console.error(`Error:${error}`))

    if (state != null) {
      setModeInsert(false)
      axios
        .get(`${API_URL}event/get-event/${state}`)
        .then((res) => {
          setDatas(res.data.data.data)
          let arry: any = []
          arry = res.data.data.photo
          const arr: any = []
          arry.map((vale: {[x: string]: string}) => {
            arr.push(`${API_URL}file/get-image/` + vale['path'])
          })
          setImageURLs(arr)
          reset({
            id: res.data.data.data[0]['id'],
            name: res.data.data.data[0]['name'],
            category_id: res.data.data.data[0]['category_id'],
            event_id: res.data.data.data[0]['event_id'],
            eventsub_id: res.data.data.data[0]['eventsub_id'],
            mo_event: res.data.data.data[0]['mo_event'],
            freq_id: res.data.data.data[0]['freq_id'],
            date_start: res.data.data.data[0]['date_start'],
            date_end: res.data.data.data[0]['date_end'],
            mode_id: res.data.data.data[0]['mode_id'],
            region_id: res.data.data.data[0]['region_id'],
            thrust_id: res.data.data.data[0]['thrust_id'],
            location: res.data.data.data[0]['location'],
            country: res.data.data.data[0]['country'],
            mo: res.data.data.data[0]['mo'],
            speaker: res.data.data.data[0]['speaker'],
            regist_att: res.data.data.data[0]['regist_att'],
            volun: res.data.data.data[0]['volun'],
            onsite_att: res.data.data.data[0]['onsite_att'],
            online_att: res.data.data.data[0]['online_att'],
            onsite_salv: res.data.data.data[0]['onsite_salv'],
            online_salv: res.data.data.data[0]['online_salv'],
            summary: res.data.data.data[0]['summary'],
            created_by: res.data.data.data[0]['created_by'],
            photos: res.data.data.data[0]['photos'],
          })

          setStartDate(new Date(moment(res.data.data.data[0]['date_start']).format()))
          setEndDate(new Date(moment(res.data.data.data[0]['date_end']).format()))

          if (res.data.data.data[0]['mode'] === 'Onsite') {
            setMdonline(false)
          } else {
            setMdonline(true)
          }
        })
        .catch((error) => console.error(`Error:${error}`))
    } else {
      setModeInsert(true)
    }
  }, [])

  const [imginfo, setImginfo] = useState<string>('')

  useEffect(() => {}, [])

  const onChange = (imageList: ImageListType) => {
    setImages(imageList as never[])
  }

  const selectevent = (e: any) => {
    var id: number = e
    const data = eventsub.filter((newData) => newData.event_type_id == id)
    setEventsubset(data)
    if (data.length == 0) {
      setMdselecteventsub(true)
    } else {
      setMdselecteventsub(false)
    }

    const datas = event.filter((newData) => newData.id == id)

    // TODO: Change this to checkbox instead
    /* if (datas[0].name == 'Missions Office') {
      setMdmission(false)
    } else {
      setMdmission(true)
    } */
  }

  const selectmode = (e: any) => {
    var id: number = e
    const data = mode.filter((newData) => newData.id == id)
    if (data[0].name === 'Onsite') {
      setMdonline(false)
    } else {
      setMdonline(true)
    }
  }

  function resetForm() {
    setValue('summary', '')
    resetField('id')
    resetField('name')
    resetField('category_id')
    resetField('event_id')
    resetField('eventsub_id')
    resetField('mo_event')
    resetField('freq_id')
    resetField('date_start')
    resetField('date_end')
    resetField('mode_id')
    resetField('region_id')
    resetField('thrust_id')
    resetField('location')
    resetField('country')
    resetField('mo')
    resetField('speaker')
    resetField('regist_att')
    resetField('volun')
    resetField('onsite_att')
    resetField('online_att')
    resetField('onsite_salv')
    resetField('online_salv')
    resetField('summary')
    resetField('bloob')
    setImageURLs([])
  }

  const editorConfiguration = {
    toolbar: [
      'undo',
      'redo',
      'bold',
      'italic',
      'blockQuote',
      'heading',
      'link',
      'numberedList',
      'bulletedList',
      'insertTable',
      'tableColumn',
      'tableRow',
      'mergeTableCells',
    ],
    table: {
      tableProperties: {
        // The default styles for tables in the editor.
        // They should be synchronized with the content styles.

        borderStyle: 'dot',
        borderColor: 'hsl(90, 75%, 60%)',
        borderWidth: '5px',
        alignment: 'left',
        width: '1000px',
        height: '500px',

        // Set the palettes for tables.
      },
    },
  }

  const handleChange = (valucon: any) => {
    setValue('country', valucon.label)
  }

  function dataURItoBlob(dataURI: string) {
    var byteString = atob(dataURI.split(',')[1])

    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    var ab = new ArrayBuffer(byteString.length)
    var ia = new Uint8Array(ab)

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    var blob = new Blob([ab], {type: mimeString})
    return blob
  }

  const toDataUrl = (url: string | URL, callback: (arg0: string | ArrayBuffer | null) => void) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      const reader = new FileReader()
      reader.onloadend = () => {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }

  const navigate = useNavigate()
  const onSubmit: SubmitHandler<Event> = (data) => {
    setLoading(true)
    var formData = new FormData()

    if (images.length > 0) {
      images.forEach((image: any) => {
        formData.append('file', dataURItoBlob(image.dataURL))
      })
    }

    var evebtsub = data.eventsub_id || 0
    var mo_event = data.mo_event || 0
    var region_id = data.region_id || 0
    var thrust_id = data.thrust_id || 0
    var mo = data.mo || ''
    var country = data.country || ''
    var location = data.location || ''
    var speaker = data.speaker || ''

    var online_salv = data.online_salv || 0
    var onsite_salv = data.onsite_salv || 0
    var online_att = data.online_att || 0
    var onsite_att = data.onsite_att || 0
    var volun = data.volun || 0
    var regist_att = data.regist_att || 0

    data.date_start = moment(startDate).format('YYYY-MM-DD')
    data.date_end = moment(endDate).format('YYYY-MM-DD')

    formData.append('id', String(data.id))
    formData.append('name', data.name)
    formData.append('category_id', String(data.category_id))
    formData.append('event_id', String(data.event_id))
    formData.append('eventsub_id', String(evebtsub))
    formData.append('mo_event', String(mo_event))
    formData.append('freq_id', String(data.freq_id))
    formData.append('date_start', data.date_start)
    formData.append('date_end', data.date_end)
    formData.append('mode_id', String(data.mode_id))
    formData.append('region_id', String(region_id))
    formData.append('thrust_id', String(thrust_id))
    formData.append('location', String(location))
    formData.append('country', String(country))
    formData.append('mo', String(mo))
    formData.append('speaker', String(speaker))
    formData.append('regist_att', String(regist_att))
    formData.append('volun', String(volun))
    formData.append('onsite_att', String(onsite_att))
    formData.append('online_att', String(online_att))
    formData.append('onsite_salv', String(onsite_salv))
    formData.append('online_salv', String(online_salv))
    formData.append('summary', data.summary || "")
    formData.append('photos', data.photos)
    formData.append('check', imageURLS.length > 0 ? 'yes' : 'no')

    if (modeinsert) {
      axios
        .post(`${API_URL}event/add-event`, formData)
        .then((res) => {
          if (res.data.data === true) {
            toasts('success', 'Data Saved Successfully !')
            resetForm()
            setLoading(false)
            setImages([])
          } else {
            setLoading(false)
            toasts('error', 'Error adding event!')
          }
        })
        .catch((error) => console.error(`Error:${error}`))
    } else {
      axios
        .put(`${API_URL}event/update-event`, formData)
        .then((res) => {
          if (res.data.data === true) {
            setLoading(false)
            resetForm()
            setImages([])
            navigate('/module/event/eventlist', {state: true})
          } else {
            setLoading(false)
            toasts('error', 'Cannot modify data, You are not the owner !')
          }
        })
        .catch((error) => console.error(`Error:${error}`))
    }
  }

  function discardEdit() {
    setModeInsert(true)
    resetForm()

    setMdevent(true)
    setMdeventsub(true)
    setMdcategory(true)
    setMdfreq(true)
    setMdmode(true)
    setMdregion(false)
    setMdthrust(false)
    setMdcountry(true)
    setMdonline(true)
    setMdmission(true)
    setMdselecteventsub(true)

    if (modeinsert == false) {
      navigate('/module/event/eventlist', {state: false})
    }
  }

  const SummaryText = () => {
    return (
      <CKEditor
        editor={ClassicEditor}
        data={getValues('summary')}
        config={editorConfiguration}
        onChange={(event: any, editor: {getData: () => any}) => {
          const data = editor.getData()
          setValue('summary', data)
        }}
      />
    )
  }

  const customStyles = {
    control: (styles: any) => ({
      ...styles,

      height: 48,
      textIndent: 10,
      borderRadius: 8,
    }),
  }

  return (
    <>
      <ToastContainer theme={'colored'} />
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Data Entry</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form className='form' onSubmit={handleSubmit(onSubmit)}>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Event Name</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        required
                        {...register('name')}
                        name='name'
                        className='form-control form-control-lg mb-3 mb-lg-0'
                      />
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Discipleship Process</span>
                </label>

                <div className='col-lg-4 fv-row'>
                  {state === null || mdcategory ? (
                    <select
                      required={!mdcategory}
                      {...register('category_id')}
                      name='category_id'
                      className='form-select form-select-lg fw-bold'
                    >
                      <option value=''>Select</option>
                      {category.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold'>
                        {' '}
                        <span>{datas[0]?.category}</span>
                      </div>

                      <div className='col-lg-4'>
                        <a
                          onClick={() => setMdcategory(true)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Event Type</span>
                </label>

                <div className='col-lg-4'>
                  <div className='col-lg-12 fv-row'>
                    {state === null || mdevent ? (
                      <select
                        required={!mdevent}
                        {...register('event_id')}
                        name='event_id'
                        onChange={(e) => selectevent(e.target.value)}
                        className='form-select form-select-lg fw-bold'
                      >
                        <option value=''>Select</option>
                        {event.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                    ) : (
                      <div className='col-lg-8 d-flex align-items-center'>
                        <div className='col-lg-4 fw-bold'>
                          {' '}
                          <span>{datas[0]?.event}</span>
                        </div>

                        <div className='col-lg-4'>
                          <a
                            onClick={() => setMdevent(true)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-4'>
                  <div className='col-lg-12 fv-row'>
                    {state === null || mdeventsub ? (
                      <select
                        required={mdselecteventsub}
                        disabled={mdselecteventsub}
                        {...register('eventsub_id')}
                        name='eventsub_id'
                        className='form-select form-select-lg fw-bold'
                      >
                        <option value=''>Select</option>
                        {eventsubset.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                    ) : (
                      <div className='col-lg-8 d-flex align-items-center'>
                        <div className='col-lg-4 fw-bold'>
                          {' '}
                          <span>{datas[0]?.eventsub}</span>
                        </div>

                        <div className='col-lg-4'>
                          <a
                            onClick={() => setMdeventsub(true)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Added new field */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Missions Office Event</span>
                </label>

                <div className='col-lg-4 fv-row'>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      {...register('mo_event')}
                      className='form-check-input'
                      name='mo_event'
                      type='checkbox'
                      onChange={(e) => {
                        if (e.target.checked) {
                          setMdmission(false)
                        } else {
                          setMdmission(true)
                        }
                      }}
                    />
                    <span className='fw-bold ps-2 fs-6'>Yes</span>
                  </label>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Frequency</span>
                </label>

                <div className='col-lg-4 fv-row'>
                  {state === null || mdfreq ? (
                    <select
                      required={!mdfreq}
                      {...register('freq_id')}
                      name='freq_id'
                      className='form-select form-select-lg fw-bold'
                    >
                      <option value=''>Select</option>
                      {freq.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold'>
                        {' '}
                        <span>{datas[0]?.freq}</span>
                      </div>

                      <div className='col-lg-4'>
                        <a
                          onClick={() => setMdfreq(true)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Date (Start-End)
                </label>

                <div className='col-lg-4'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      {/* <input type='date' required
                                                {...register("date_start")} name='date_start'
                                                className='form-control form-control-lg mb-3 mb-lg-0' /> */}

                      <DatePicker
                        dateFormat='dd MMM yyyy'
                        calendarStartDay={1}
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date)
                        }}
                        className='form-control form-control-lg mb-3 mb-lg-0'
                      />

                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-4'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      {/* <input type='date' required
                                                {...register("date_end")} name='date_end'
                                                className='form-control form-control-lg mb-3 mb-lg-0' /> */}

                      <DatePicker
                        dateFormat='dd MMM yyyy'
                        calendarStartDay={1}
                        selected={endDate}
                        onChange={(date) => {
                          setEndDate(date)
                        }}
                        className='form-control form-control-lg mb-3 mb-lg-0'
                      />
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Mode</span>
                </label>

                <div className='col-lg-4 fv-row'>
                  {state === null || mdmode ? (
                    <select
                      required={!mdmode}
                      {...register('mode_id')}
                      name='mode_id'
                      onChange={(e) => selectmode(e.target.value)}
                      className='form-select form-select-lg fw-bold'
                    >
                      <option value=''>Select</option>
                      {mode.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold'>
                        {' '}
                        <span>{datas[0]?.mode}</span>
                      </div>

                      <div className='col-lg-4'>
                        <a
                          onClick={() => setMdmode(true)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Region</span>
                </label>

                <div className='col-lg-4 fv-row'>
                  {state === null || mdregion ? (
                    <select
                      disabled={mdmission}
                      required={mdregion || mdmission}
                      {...register('region_id')}
                      name='region_id'
                      className='form-select form-select-lg fw-bold'
                    >
                      <option value=''>Select</option>
                      {region.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold'>
                        {' '}
                        <span>{datas[0]?.region}</span>
                      </div>

                      <div className='col-lg-4'>
                        <a
                          onClick={() => setMdregion(true)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Thrust</span>
                </label>

                <div className='col-lg-4 fv-row'>
                  {state === null || mdthrust ? (
                    <select
                      disabled={mdmission}
                      required={mdthrust || mdmission}
                      {...register('thrust_id')}
                      name='thrust_id'
                      className='form-select form-select-lg fw-bold'
                    >
                      <option value=''>Select</option>
                      {thrust.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold'>
                        {' '}
                        <span>{datas[0]?.thrust}</span>
                      </div>

                      <div className='col-lg-4'>
                        <a
                          onClick={() => setMdthrust(true)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Location/City</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        placeholder='Where?'
                        {...register('location')}
                        name='location'
                        className='form-control form-control-lg mb-3 mb-lg-0'
                      />
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Country</span>
                </label>

                <div className='col-lg-4 fv-row'>
                  {state === null || mdcountry ? (
                    <Select
                      className='fw-bold'
                      styles={customStyles}
                      options={options}
                      value={valucon}
                      onChange={handleChange}
                    />
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold'>
                        {' '}
                        <span>{datas[0]?.country}</span>
                      </div>

                      <div className='col-lg-4'>
                        <a
                          onClick={() => setMdcountry(true)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Ministry Organizer</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        placeholder='Organizer'
                        {...register('mo')}
                        name='mo'
                        className='form-control form-control-lg mb-3 mb-lg-0'
                      />
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Speaker/s Organizer</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        placeholder='Speaker,separated by comma if multiple'
                        {...register('speaker')}
                        name='speaker'
                        className='form-control form-control-lg mb-3 mb-lg-0'
                      />
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Registered Attendee</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='number'
                        min='0'
                        placeholder='0'
                        {...register('regist_att')}
                        name='regist_att'
                        className='form-control form-control-lg mb-3 mb-lg-0'
                      />
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Volunteers</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='number'
                        min='0'
                        placeholder='0'
                        {...register('volun')}
                        name='volun'
                        className='form-control form-control-lg mb-3 mb-lg-0'
                      />
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Onsite Attendee
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='number'
                        min='0'
                        required
                        placeholder='0'
                        {...register('onsite_att')}
                        name='onsite_att'
                        className='form-control form-control-lg mb-3 mb-lg-0'
                      />
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {mdonline && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Online Attendee</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <input
                          type='number'
                          min='0'
                          placeholder='0'
                          {...register('online_att')}
                          name='online_att'
                          className='form-control form-control-lg mb-3 mb-lg-0'
                        />
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Onsite Salvations</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='number'
                        min='0'
                        placeholder='0'
                        {...register('onsite_salv')}
                        name='onsite_salv'
                        className='form-control form-control-lg mb-3 mb-lg-0'
                      />
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {mdonline && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Online Salvations</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <input
                          type='number'
                          min='0'
                          placeholder='0'
                          {...register('online_salv')}
                          name='online_salv'
                          className='form-control form-control-lg mb-3 mb-lg-0'
                        />
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Summary</label>

                <div className='col-lg-8 fv-row'>
                  <SummaryText />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Photos</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxFileSize={500000}
                    acceptType={['jpg', 'gif', 'png']}
                    maxNumber={5}
                  >
                    {({imageList, onImageUpload, onImageRemove, isDragging, dragProps, errors}) => (
                      <div>
                        {imageURLS.length > 0 ? (
                          <div>
                            {imageURLS.map((imageSrc, index) => (
                              <div key={index} style={{float: 'left'}}>
                                <img
                                  key={index}
                                  src={imageSrc}
                                  className='me-2 mt-2'
                                  alt='not fount'
                                  width={'80px'}
                                  height={'80px'}
                                />
                              </div>
                            ))}

                            {imageURLS.length > 0 && (
                              <button
                                onClick={() => setImageURLs([])}
                                type='button'
                                style={{border: '0px', color: 'red'}}
                                className='me-3'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                            )}
                          </div>
                        ) : (
                          <div>
                            <button
                              className='btn btn-muted'
                              type='button'
                              style={isDragging ? {color: 'red'} : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </button>
                            &nbsp;
                            {imageList.map((image, index) => (
                              <div key={index} style={{float: 'left'}}>
                                <img
                                  src={image.dataURL}
                                  alt=''
                                  className='me-2 mt-2'
                                  width={'80px'}
                                  height={'80px'}
                                />
                                <div className='image-item__btn-wrapper'>
                                  <button
                                    style={{border: '0px', color: 'red', fontWeight: 'bold'}}
                                    onClick={() => onImageRemove(index)}
                                  >
                                    X
                                  </button>
                                </div>
                              </div>
                            ))}
                            {errors && (
                              <div>
                                {errors.maxNumber && (
                                  <span style={{color: 'red'}}>
                                    *Number of selected images exceed maxNumber
                                  </span>
                                )}
                                {errors.maxFileSize && (
                                  <span style={{color: 'red'}}>
                                    *Selected file size exceed maxFileSize
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='reset'
                onClick={discardEdit}
                className='btn btn-default'
                disabled={loading}
              >
                Cancel
              </button>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default EventRegistration
