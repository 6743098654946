import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { EventHeader } from './EventHeader'
import EventRegistration from './EventRegistration'
import EventList from './EventList'



const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Event',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

type Props = {
  read: boolean
  write: boolean
}


const EventPage: React.FC<Props> = ({ read, write }) => {
  return (
    <Routes>
      <Route
        element={
          <>
            <EventHeader />
            <Outlet />
          </>
        }
      >
        {write ? (
          <Route
            path='eventregistration'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Data Entry</PageTitle>

                <EventRegistration />

              </>
            }
          />

        ) : (

          <Route path='*' element={<Navigate to='/error/404' />} />
        )}



        {read ? (
          <Route
            path='eventlist'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Event Data</PageTitle>
                <EventList />

              </>
            }
          />

        ) : (
          <Route path='*' element={<Navigate to='/error/404' />} />
        )}

        <Route index element={<Navigate to='/module/event/eventregistration' />} />
      </Route>
    </Routes>
  )
}

export default EventPage
