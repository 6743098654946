import axios from 'axios'
import React, {useState, useEffect} from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'
import {ToastContainer} from 'react-toastify'
import {number} from 'yup'
import {KTSVG} from '../../../_metronic/helpers'
import Utilities from '../../services/Utilities'
import 'react-toastify/dist/ReactToastify.css'
import {useLocation, useNavigate} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'react-moment'
import {stat} from 'fs'
import {useAuth} from '../auth'
import moment from 'moment'
const API_URL = process.env.REACT_APP_API_URL

type Centers = {
  id: number
  subcount: string
  name: string
}

type Levels = {
  id: number
  subcount: string
  name: string
}

type Time = {
  id: number
  level_id: number
  subcount: string
  name: string
}

type Tng = {
  id: number
  date: string
  centre_id: number
  level_id: number
  special: boolean
  timing_id: number
  onsite_att: number | 0
  online_att: number | 0
  note: string
  created_at: string
  created_by: string
}

type TngData = {
  id: number
  date: string
  centre_id: number
  center: string
  level_id: number
  level: string
  timing_id: number
  timing: string
  onsite_att: number
  online_att: number
  note: string
  created_at: string
}

const TngRegistration = () => {
  const {toasts} = Utilities()
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [datas, setDatas] = useState<TngData[]>([])

  const [loading, setLoading] = useState(false)

  const [centers, setCenters] = useState<Centers[]>([])

  const [time, setTime] = useState<Time[]>([])

  const [timeset, setTimeset] = useState<Time[]>([])

  const [levels, setLevels] = useState<Levels[]>([])

  const {state} = useLocation()

  const {register, handleSubmit, reset, resetField} = useForm<Tng>({})

  const [timingdis, setTimingdis] = useState(false)

  const [mode, setMode] = useState(false)


  useEffect(() => {
    const history = createBrowserHistory()
    history.replace('', null)

    axios
      .get(`${API_URL}center/get-all-centers`)
      .then((res) => {
        setCenters(res.data.data)
      })
      .catch((error) => console.error(`Error:${error}`))

    axios
      .get(`${API_URL}tng-level/get-all-tng-levels`)
      .then((res) => {
        setLevels(res.data.data)
      })
      .catch((error) => console.error(`Error:${error}`))

    axios
      .get(`${API_URL}timing/get-all-level-timings`)
      .then((res) => {
        setTime(res.data.data)
      })
      .catch((error) => console.error(`Error:${error}`))

    if (state != null) {
      setMode(false)
      axios
        .get(`${API_URL}tng/get-tng/${state}`)
        .then((res) => {
          setDatas(res.data.data)
          reset({
            id: res.data.data[0]['id'],
            date: res.data.data[0]['date'],
            level_id: res.data.data[0]['level_id'],
            special: res.data.data[0]['special'],
            centre_id: res.data.data[0]['center_id'],
            timing_id: res.data.data[0]['timing_id'],
            online_att: res.data.data[0]['online_att'],
            onsite_att: res.data.data[0]['onsite_att'],
            note: res.data.data[0]['note'],
          })

          setStartDate(new Date(moment(res.data.data[0]['date']).format()))
        })
        .catch((error) => console.error(`Error:${error}`))
    } else {
      setMode(true)
    }
  }, [])

  const selectservice = (e: any) => {
    var id: number = e
    const data = time.filter((newData) => newData.level_id == id);
    setTimeset(data)
    reset({timing_id: data.length > 0 ? data[0].id : undefined});
    if (data.length == 0) {
      setTimingdis(true);
    } else {
      setTimingdis(false)
    }
  }

  function resetForm() {
    resetField('date')
    resetField('centre_id')
    resetField('level_id')
    resetField('special')
    resetField('timing_id')
    resetField('onsite_att')
    resetField('online_att')
    resetField('note')
    resetField('created_at')
  }

  const navigate = useNavigate()
  const onSubmit: SubmitHandler<Tng> = (data) => {
    setLoading(true)

    data.date = moment(startDate).format('YYYY-MM-DD')
    if (mode) {
      axios
        .post(`${API_URL}tng/add-tng`, data)
        .then((res) => {
          if (res.data.data === true) {
            toasts('success', 'Data Saved Successfully !')
            resetForm()
            setLoading(false)
          } else {
            setLoading(false)
            resetForm()
            toasts('error', res.data.message)
          }
        })
        .catch((error) => {
          setLoading(false)
          resetForm()
          toasts('error', 'Error adding TNG')
          console.error(`Error:${error}`)
        })
    } else {
      axios
        .put(`${API_URL}tng/update-tng`, data)
        .then((res) => {
          if (res.data.data === true) {
            setLoading(false)
            resetForm()
            navigate('/module/tng/tngweekly', {state: true})
          } else {
            setLoading(false)
            toasts('error', 'Cannot modify data, You are not the owner !')
          }
        })
        .catch((error) => console.error(`Error:${error}`))
    }
  }

  function discardEdit() {
    setMode(true)

    if (mode == false) {
      navigate('/module/tng/tngweekly', {state: false})
    }
  }

  return (
    <>
      <ToastContainer theme={'colored'} />
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Data Entry</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form className='form' onSubmit={handleSubmit(onSubmit)}>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Date</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <DatePicker
                        // disabled={state != null}
                        dateFormat='dd MMM yyyy'
                        selected={startDate}
                        calendarStartDay={1}
                        onChange={(date) => {
                          setStartDate(date)
                        }}
                        includeDateIntervals={[{start: new Date('1960/01/1'), end: new Date()}]}
                        className='form-control form-control-lg mb-3 mb-lg-0'
                      />

                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Centre</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  {state === null ? (
                    <select
                      required={true}
                      {...register('centre_id')}
                      name='centre_id'
                      className='form-select form-select-lg fw-bold'
                    >
                      <option value=''>Select</option>
                      {centers.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold col-form-label fs-6'>
                        {' '}
                        <span>{datas[0]?.center}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Level</span>
                </label>

                <div className='col-lg-4 fv-row'>
                  {state === null ? (
                    <div className='d-flex align-items-center'>
                      <select
                        required={true}
                        {...register('level_id')}
                        name='level_id'
                        onChange={(e) => selectservice(e.target.value)}
                        className='form-select form-select-lg fw-bold'
                      >
                        <option value=''>Select</option>
                        {levels.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  ) : (
                    <div className='col-lg-12 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold col-form-label fs-6'>
                        {' '}
                        <span>{datas[0]?.level}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='col-lg-4 col-sm-4 fv-row d-flex align-items-center'>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      {...register('special')}
                      className='form-check-input'
                      name='special'
                      type='checkbox'
                    />
                    <span className='fw-bold ps-2 fs-6'>Special Class</span>
                  </label>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                  <span>Time</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  {state === null ? (
                    <select
                      required={true}
                      {...register('timing_id')}
                      name='timing_id'
                      disabled={timingdis}
                      className='form-select form-select-lg fw-bold'
                    >
                      {timeset.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='col-lg-4 fw-bold col-form-label fs-6'>
                        {' '}
                        <span>{datas[0]?.timing}</span>
                      </div>
                    </div>
                  )}

                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Onsite Attendee</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    required
                    {...register('onsite_att', {
                      valueAsNumber: true,
                    })}
                    name='onsite_att'
                    type='number'
                    min={1}
                    className='form-control form-control-lg'
                    placeholder='0'
                  />
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Online Attendee</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    {...register('online_att', {
                      valueAsNumber: true,
                    })}
                    name='online_att'
                    type='number'
                    className='form-control form-control-lg'
                    placeholder='0'
                  />
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Note</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    {...register('note')}
                    name='note'
                    type='text'
                    className='form-control form-control-lg'
                    placeholder='Please type your notes here'
                  />
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'></div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='reset'
                onClick={discardEdit}
                className='btn btn-default'
                disabled={loading}
              >
                Cancel
              </button>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default TngRegistration
