
  
  export interface IUpdateEmail {
    newEmail: string
    confirmPassword: string
  }
  
  export interface IUpdatePassword {
    currentPassword: string
    newPassword: string
    passwordConfirmation: string
  }
  
  export interface IConnectedAccounts {
    google: boolean
    github: boolean
    stack: boolean
  }
  

  
  export interface IDeactivateAccount {
    confirm: boolean
  }
  
  
  
  export const updatePassword: IUpdatePassword = {
    currentPassword: '',
    newPassword: '',
    passwordConfirmation: '',
  }
  


  
  
  export const deactivateAccount: IDeactivateAccount = {
    confirm: false,
  }
  