import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTSVG } from '../../../../_metronic/helpers';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/esm/Spinner';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const API_URL = process.env.REACT_APP_API_URL

type AttendanceTrendData = {
    average: number;
    service_data: number[];
    timing: string | null;
  };

type ServiceCategories = {
    id: number;
    name: string;
    service_type: string;
}
type Centre = {
    id: number;
    name: string;
    created_at?: string;
}
function sumArray(arr: number[]): number {
    return arr.reduce((acc, value) => acc + value, 0);
}

function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function formatDisplayDate(date: string) {
    const dateObject = new Date(date);
    const day = dateObject.toLocaleDateString('en-US', { day: '2-digit' });
    const month = dateObject.toLocaleDateString('en-US', { month: 'short' });
    return `${day} ${month}`; 
}
const AttendanceTrend = () => {
    const [loading, setLoading] = useState(false);
    const defaultStartDate = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate() - 5 * 7);
    const [startDate, setStartDate] = useState<Date>(defaultStartDate);
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [centres, setCentres] = useState<Array<Centre>>();
    const [selectedCentre, setSelectedCentre] = useState<number>(1);
    const [services, setServices] = useState<Array<ServiceCategories>>();
    const [selectedService, setSelectedService] = useState<number>(1);
    const [mainCategories, setMainCategories] = useState<Array<string>>();
    const [selectedMainCategory, setSelectedMainCategory] = useState<string>('worship');
    const [attendanceTrend, setAttendanceTrend] = useState<Array<AttendanceTrendData>>();
    const [attendanceDates, setAttendanceDates] = useState<Array<string>>();
    const [attendanceTrendTotal, setAttendanceTrendTotal] = useState<number[]>();


    useEffect(() => {
        applyFilter();
    }, []);



    const applyFilter = () => {
        try {
            axios.get(`${API_URL}center/get-all-centers`).then((res) => {
                let result = res.data.data;
                setCentres(result);
                }).catch(error => console.error(`Error:${error}`));
            
            axios.get(`${API_URL}combined-report/get-service-combinations`).then((res) => {
                setMainCategories(res.data.category);
                setServices(res.data.subcategories);
                }).catch(error => console.error(`Error:${error}`));

            let params = {
                params: {
                  service_type: selectedMainCategory,
                  center_id: selectedCentre,
                  service_id: selectedService,
                  start_date: formatDate(startDate),
                  end_date: formatDate(endDate),
                },
              };
            setLoading(true);
            axios.get(`${API_URL}combined-report/get-attendance-trend`,
            params
            ).then((res) => {
                setAttendanceTrend(res.data.data);
                setAttendanceDates(res.data.dates);
                setAttendanceTrendTotal(res.data.total);
                setLoading(false);
            }).catch(error => console.error(`Error:${error}`));
          } catch (error) {
            console.error('Error applying filter:', error);
            setLoading(false);
          }
    }




    const AttendanceTrendTable = () => {

        return (

            <table
                id='kt_table_users'
                className='align-middle table table-row-bordered gy-7 gs-7'>
                <thead className='fw-bold bg-light text-muted fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                    <tr>
                        <th key={0}>Timing</th>
                        {attendanceDates?.map((item, x) => {
                            return (
                                <th key={x+1}>{formatDisplayDate(item)}</th>
                            )

                        })}
                        <th key={'total_header'}>Average</th>
                    </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold'>
                    {attendanceTrend?.map((rowData, n) => {
                        return (<tr key={'row ' + n}>
                        <td key={0}>{rowData.timing}</td>

                        {rowData?.service_data?.map((item, index) => {
                            return (
                                    <td key={index + 1}>{item}</td>
                            );
                        })}
                        <td key={'average_' + n}>{rowData.average}</td>
                    </tr>);
                    })}

                    <tr>
                        <th className='fw-bold fs-6 text-gray-800'>Total</th>
                        {attendanceTrendTotal?.map((item, index) => {
                            return (
                                <th key={'col-total-' + index}>
                                    {item}
                                </th>
                            );
                        })}
                    </tr>
                </tbody>
            </table>
        )

    }

    const AttendanceTrendChart = () => {
        // Sample data structure
        const series = attendanceTrend?.map((item, index) => {
            return {
                name: item.timing || '',
                data: item.service_data,
            }
        }) || [];
      
        const options: ApexOptions = {
          chart: {
            type: 'line',
          },
          xaxis: {
            categories: attendanceDates || [],
            tooltip: {
                enabled: false,
            }
          },
          markers: {
            size: 6, // Adjust the size of the markers as needed
            strokeWidth: 0, // Set the stroke width to 0 to hide the border
            hover: {
              size: 8, // Adjust the size of markers on hover if needed
            },
          },
          grid: {
            show: true,
          },
        };
      
        return (
          <div>
            <Chart options={options} series={series} type="line" height={350} />
          </div>
        );
      };


    return (
        <div>
            <div className='card mb-5 mb-xl-10'>
                <KTCard>
                    <div className='card-header border-0 pt-6'>
                        <div className='card-title mx-2 p-0 mb-6'>
                            <h3 >Attendance Trend</h3>
                        </div>
                    </div>
                        <div className='card-toolbar'>
                            <div className=' justify-content-start mx-9'>
                                <label className='mb-2'>
                                    <h4 className='fw-bold text-muted mx-2'>From</h4>
                                    <DatePicker className="form-control w-150px me-2 col-ms-12"
                                        dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date) => setStartDate(date || new Date())} name="startDate" />
                                </label>
                                <label className='mb-2'>
                                    <h4 className='fw-bold text-muted mx-2'>To</h4>
                                    <DatePicker className="form-control w-150px me-2 col-ms-12"
                                        dateFormat="yyyy-MM-dd" selected={endDate} onChange={(date) => setEndDate(date || new Date())} name="endDate" />
                                </label>
                                <label className='mx-2'>
                                    <h4 className='fw-bold text-muted mx-2'>Centre</h4>
                                <select onChange={(e) => setSelectedCentre(parseInt(e.target.value))}
                                    className='form-select w-200px fw-bolder me-2'
                                    data-kt-select2='true'
                                    data-placeholder='Select Centre'
                                    data-allow-clear='true'
                                    data-kt-user-table-filter='role'
                                    data-hide-search='true'
                                >
                                    {centres?.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            );
                                        })}
                                </select>
                                </label>
                                <label className='mx-2'>
                                    <h4 className='fw-bold text-muted mx-2'>Service Type</h4>
                                <select onChange={(e) => setSelectedMainCategory(e.target.value)}
                                    className='form-select fw-bolder me-2'
                                    data-kt-select2='true'
                                    data-placeholder='Select Service'
                                    data-allow-clear='true'
                                    data-kt-user-table-filter='role'
                                    data-hide-search='true'
                                >
                                    {mainCategories?.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item.charAt(0).toUpperCase() + item.slice(1)}</option>
                                            );
                                        })}
                                </select>
                                </label>
                                <label className='mx-2'>
                                    <h4 className='fw-bold text-muted mx-2'>Service</h4>
                                <select onChange={(e) => setSelectedService(parseInt(e.target.value))}
                                    className='form-select fw-bolder me-2'
                                    data-kt-select2='true'
                                    data-placeholder='Select Service'
                                    data-allow-clear='true'
                                    data-kt-user-table-filter='role'
                                    data-hide-search='true'
                                >
                                    {services?.map((item, index) => {
                                        if (item.service_type == selectedMainCategory){
                                            return (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            );
                                        }
                                        })}
                                </select>
                                </label>
                                <Button
                                variant="primary"
                                onClick={applyFilter}
                                disabled={loading}
                                >
                                {loading ? (
                                    <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    {' Updating...'}
                                    </>
                                ) : (
                                    'Update'
                                )}
                                </Button>
                            </div>
                        </div>

                    <KTCardBody className='py-4'>
                        <div className='table-responsive'>
                            <AttendanceTrendTable />
                        </div>
                        <AttendanceTrendChart />
                    </KTCardBody>
                </KTCard>
            </div>

        </div>

    )
}

export default AttendanceTrend; 