import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTSVG } from '../../../../_metronic/helpers';
import Moment from 'react-moment'
import clsx from 'clsx';
import FileSaver from 'file-saver';
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Utilities from '../../../services/Utilities'
import { ToastContainer } from 'react-toastify'

const API_URL = process.env.REACT_APP_API_URL


type Filter = {
    name: string;
}

type Data = {
    id: number;
    date: string;
    level_id: number;
    level: string;
    centre_id: number;
    center: string;
    timing_id: number;
    timing: string;
    onsite_att: number | 0;
    online_att: number | 0;
    parent_server: number | 0;
    salvation: number | 0;
    adult_dmk: number | 0;
    youth_dmk: number | 0;
    note: string;
    special: boolean;
    created_at: string;
}




const Tng = () => {

    const [start, setStart] = useState<Date | null>(new Date());
    const [end, setEnd] = useState<Date | null>(new Date());
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [centre, setCentre] = useState<Filter[]>([]);
    const [time, setTime] = useState<Filter[]>([]);
    const [filter, setFilter] = useState<Filter[]>([]);
    const [data, setData] = useState<Data[]>([]);
    const [select, setSelect] = useState<number>(0);
    const [value, setValue] = useState<number>(0);
    const [prev, setPrev] = useState<string>('');
    const [next, setNext] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState(false)
    const { toasts } = Utilities();

    useEffect(() => {
        applyLatest("1")
        getDataFilter();
    }, []);

    function getDataFilter() {
        axios.get(`${API_URL}tng-report/get-filters`).then((res) => {
            setCentre(res.data.centre);
            setTime(res.data.time);

        }).catch(error => console.error(`Error:${error}`));
    }
    const selectfilterby = (e: any) => {
        var id: number = e
        if (id == 1) {
            setFilter(centre)
        } else if (id == 2) {
            setFilter(time)
        } else {
            setFilter([])
        }

        setSelect(id)


    }

    const selectvalue = (e: any) => {
        var id: number = e
        setValue(id)


    }

    const [pagno, setPageno] = useState<string>();
    const [dataexcel, setDataexcel] = useState([]);


    const applyFilter = (no: string | undefined) => {
        setOpen(true)
        let starts = moment(start).format('YYYY-MM-DD');
        let ends = moment(end).format('YYYY-MM-DD');
        axios.get(`${API_URL}tng-report/get-filtered-tng-history?page=${no}&filter=${select}&value=${value}&start=${starts}&end=${ends}`).then((res) => {
            setPrev(res.data.prev);
            setNext(res.data.next);
            setData(res.data.data);
            setPageno(no);
            setDataexcel(res.data.data);
            setIsEditable(res.data.editable);
        }).catch(error => console.error(`Error:${error}`));
    }


    const applyLatest = (no: string | undefined) => {

        axios.get(`${API_URL}tng-report/get-latest-tng-history?page=${no}`).then((res) => {
            setPrev(res.data.prev)
            setNext(res.data.next)
            setData(res.data.data)
            setPageno(no);
            setDataexcel(res.data.data)
            setIsEditable(res.data.editable);
            setOpen(false)
        }).catch(error => console.error(`Error:${error}`));
    }

    const updatePage = (no: string) => {
        if (open) {

            applyFilter(no)
        } else {
            applyLatest(no)
        }

    }

    const navigate = useNavigate();
    const [optSmModal, setOptSmModal] = useState(false);
    const toggleShowSm = () => setOptSmModal(!optSmModal);
    const gotoEdit=(id:number)=>
    {
        navigate('/module/tng/tngregistration', { state:  id});

    }
    const[id,setId]=useState(0)
    const gotoDel=(id:number)=>
    {
        setId(id)
        toggleShowSm()

    }

    const gotoAddlInfo=(id:number)=>
    {
        navigate('/module/tng/tngaddlinfo', { state:  id});

    }
    const deleteData=()=>{
        let data={
            id:id
        }
        setLoading(true)
        axios.delete(`${API_URL}tng/delete-tng`,{data}).then((res) => {
            if (res.data.data === true) {
                toasts("success", "Data Deleted Successfully !");
                applyLatest("1")
                setLoading(false)
                toggleShowSm()
            }else{
                setLoading(false)
                toggleShowSm()
                toasts("error", "Cannot delete data, You are not the owner !");
            }
        }).catch(error => console.error(`Error:${error}`));

    }
    const ModalConfirm=()=>{
        return (
          <>
           <Modal show={optSmModal} onHide={toggleShowSm} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure want to delete the data ?</Modal.Body>
                <Modal.Footer>

                    <button className='btn btn-danger' onClick={() => deleteData()} disabled={loading}>{!loading && 'Confirm'}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}</button>
                    <Button variant="secondary" onClick={toggleShowSm}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
          </>
        )
      }

    const getPdf = () => {

        if(open){
            getPdffilt();

        }else{
            getPdflts();
        }


    }

    const getExcel = () => {

        if(open){
            getExcelFiltered();

        }else{
            getExcelLatest();
        }


    }
    
    const getPdffilt = () => {
        let starts = moment(start).format('YYYY-MM-DD');
        let ends = moment(end).format('YYYY-MM-DD');
        axios.get(`${API_URL}tng-report/get-filtered-tng-history-pdf?filter=${select}&value=${value}&start=${starts}&end=${ends}`, {
            responseType: 'blob'
        }).then((res) => {
            console.log(res)
            const file = new Blob(
                [res.data],
                { type: 'application/pdf' });

            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }).catch(error => console.error(`Error:${error}`))
    }

    const getPdflts = () => {

        axios.get(`${API_URL}tng-report/get-tng-history-report-pdf`, {
            responseType: 'blob'
        }).then((res) => {
            const file = new Blob(
                [res.data],
                { type: 'application/pdf' });

            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }).catch(error => console.error(`Error:${error}`))
    }

    const getExcelFiltered = () => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const fileName = "excel files";
        let starts = moment(start).format('YYYY-MM-DD');
        let ends = moment(end).format('YYYY-MM-DD');
        axios.get(`${API_URL}tng-report/get-filtered-tng-history-excel-data?filter=${select}&value=${value}&start=${starts}&end=${ends}`).then((res) => {
            const result = res.data.data;
            const columnOrder: string[] = [
                "id",
                "date",
                "center",
                "level",
                "timing",
                "special",
                "onsite_att",
                "online_att",
                "parent_server",
                "salvation",
                "adult_dmk",
                "youth_dmk",
                "note",
                "created_at"
            ];
            const customizedResult = result.map((item: Data) => {
                const newItem: any = {};
                columnOrder.forEach((key) => {
                    if (item.hasOwnProperty(key)) {
                        newItem[key] = item[key as keyof Data];
                    }
                });
                return newItem;
            });
            const ws = XLSX.utils.json_to_sheet(customizedResult, {header: columnOrder});
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        }).catch(error => console.error(`Error:${error}`));
    }
    const getExcelLatest = () => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const fileName = "excel files";
        axios.get(`${API_URL}tng-report/get-latest-tng-history-excel-data?`).then((res) => {
            const result = res.data.data;
            const columnOrder: string[] = [
                "id",
                "date",
                "center",
                "level",
                "timing",
                "special",
                "onsite_att",
                "online_att",
                "parent_server",
                "salvation",
                "adult_dmk",
                "youth_dmk",
                "note",
                "created_at"
            ];
            const customizedResult = result.map((item: Data) => {
                const newItem: any = {};
                columnOrder.forEach((key) => {
                    if (item.hasOwnProperty(key)) {
                        newItem[key] = item[key as keyof Data];
                    }
                });
                return newItem;
            });
            const ws = XLSX.utils.json_to_sheet(customizedResult, {header: columnOrder});
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        }).catch(error => console.error(`Error:${error}`));
    }


    const Paginations = () => {
        return (
            <div className='row mt-10'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                    <div id='kt_table_users_paginate'>
                        <ul className='pagination'>
                            <li>
                                <a onClick={() => updatePage(prev)}
                                    className={clsx('page-link me-5', { active: prev != null })}
                                    style={{ cursor: 'pointer' }}
                                > Previous</a>
                            </li>



                            <li>
                                <a onClick={() => updatePage(next)}
                                    className={clsx('page-link me-15', { active: next != null })}
                                    style={{ cursor: 'pointer' }}
                                > Next</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div>
            <ToastContainer theme={"colored"} />
            <ModalConfirm/>
            <KTCard>
                <div className='card-header border-0 pt-6'>
                    <div className='card-title'>
                        <div className='d-flex align-items-center position-relative my-1'>


                        </div>
                    </div>
                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end me-3'>

                            

                            <div className='d-flex justify-content-end me-3'>
                                <DatePicker className="form-control" calendarStartDay={1}
                                    dateFormat="dd MMM yyyy" selected={start} onChange={(date) => setStart(date)} name="date" />
                            </div>

                            <div className='d-flex justify-content-end me-3'>
                                <DatePicker className="form-control"calendarStartDay={1}
                                    dateFormat="dd MMM yyyy" selected={end} onChange={(date) => setEnd(date)} name="date" />
                            </div>




                        </div>

                        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

                            <button
                                type='button'
                                className='btn btn-light-primary me-3'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                            >
                                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                                Filter
                            </button>



                            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                                <div className='px-7 py-5'>
                                    <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                                </div>


                                <div className='separator border-gray-200'></div>

                                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                    <div className='mb-10'>
                                        <select
                                            className='form-select fw-bolder'
                                            data-kt-select2='true'
                                            data-placeholder='Select option'
                                            data-allow-clear='true'
                                            data-kt-user-table-filter='role'
                                            data-hide-search='true' onChange={(e) => selectfilterby(e.target.value)}
                                        >
                                            <option value=''></option>
                                            <option value='1'>Centre</option>
                                            <option value='2'>Time</option>
                                        </select>
                                    </div>

                                    <div className='mb-10'>
                                        <select
                                            className='form-select fw-bolder'
                                            data-kt-select2='true'
                                            data-placeholder='Select option'
                                            data-allow-clear='true'
                                            data-kt-user-table-filter='two-step'
                                            data-hide-search='true' onChange={(e) => selectvalue(e.target.value)}
                                        >
                                            <option value=''></option>
                                            {filter.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.name}>{item.name}</option>

                                                )
                                            })}
                                        </select>
                                    </div>

                                    <div className='d-flex justify-content-end'>
                                        <button
                                            type='button'
                                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                                            data-kt-menu-dismiss='true'
                                            data-kt-user-table-filter='reset'
                                        >
                                            Reset
                                        </button>
                                        <button onClick={() => applyFilter("1")}
                                            type='button'
                                            className='btn btn-primary fw-bold px-6'
                                            data-kt-menu-dismiss='true'
                                            data-kt-user-table-filter='filter'
                                        >
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>



                            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                                <button
                                    type='button'
                                    className='btn btn-light-primary me-3'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
                                    Export
                                </button>

                                <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                                    <div className='px-7 py-5'>
                                        <div className='fs-5 text-dark fw-bolder'>Export Options</div>
                                    </div>
                                    <div className='separator border-gray-200'></div>

                                    <div className='px-7 py-5' data-kt-user-table-filter='form'>

                                        <div className='mb-12'>
                                            <button onClick={() => getPdf()}
                                                type='button'
                                                className='btn btn-light-primary me-3'
                                                data-kt-menu-placement='bottom-end'
                                            >

                                                PDF
                                            </button>

                                            <button onClick={() => getExcel()}
                                                type='button'
                                                className='btn btn-light-primary me-3'
                                                data-kt-menu-placement='bottom-end'
                                            >

                                                Excel
                                            </button>
                                        </div>

                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>
                </div>

                <KTCardBody className='py-4'>
                    <div className='table-responsive'>
                        <table
                            id='kt_table_users'
                            className='align-middle table table-row-bordered gy-7 gs-7'>
                            <thead>
                                <tr className='fw-bold bg-light text-muted fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                                    <th className='ps-4 min-w-200 rounded-start'>Date</th>
                                    <th className='min-w-125px'>Centre</th>
                                            <th className='min-w-125px'>Level</th>
                                            <th className='min-w-125px'>Timing</th>
                                            <th className='min-w-125px'>Special Service</th>
                                            <th className='min-w-100px'>Onsite Attendance</th>
                                            <th className='min-w-100px'>Online Attendance</th>
                                            <th className='min-w-100px'>Parent Server</th>
                                            <th className='min-w-100px'>Salvation</th>
                                            <th className='min-w-100px'>Adult DMK</th>
                                            <th className='min-w-100px'>Youth DMK</th>
                                            <th className='min-w-100px'>Note</th>
                                            <th className='min-w-100px'>Created At</th>
                                            {isEditable ? 
                                                (<th className='min-w-100px'></th>) :
                                                (null)
                                                }
                                </tr>

                            </thead>
                            <tbody>
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.date}</td>
                                                    <td>{item.center}</td>
                                                    <td>{item.level}</td>
                                                    <td>{item.timing}</td>
                                                    {item.special ? (<td>Yes</td>) : (<td>No</td>)}
                                                    <td>{item.onsite_att}</td>
                                                    <td>{item.online_att}</td>
                                                    <td>{item.parent_server}</td>
                                                    <td>{item.salvation}</td>
                                                    <td>{item.adult_dmk}</td>
                                                    <td>{item.youth_dmk}</td>
                                                    <td>{item.note}</td>
                                                    <td><Moment format="DD-MM-YYYY HH:mm">{item.created_at}</Moment> </td>
                                                    {isEditable ? 
                                                    ( <td className='text-end'>
                                                    <div className='btn-group' role='group'>
                                                        <button onClick={()=>gotoEdit(item.id)}
                                                            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                                            style={{ display: 'inline-flex', whiteSpace: 'nowrap' }}
                                                            >
                                                            Edit
                                                        </button>
                                                        <button onClick={()=>gotoAddlInfo(item.id)}
                                                            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                                            style={{ display: 'inline-flex', whiteSpace: 'nowrap' }}
                                                            >
                                                            Edit Add'l Info
                                                        </button>
                                                        <button onClick={()=>gotoDel(item.id)}
                                                            className='btn btn-bg-danger btn-color-white btn-active-color-primary btn-sm px-4'
                                                            style={{ display: 'inline-flex', whiteSpace: 'nowrap' }}
                                                            >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </td>)
                                                    :
                                                    (null)
                                                }
                                        </tr>
                                    );
                                })}

                            </tbody>
                        </table>
                    </div>
                    <Paginations />
                </KTCardBody>

            </KTCard>
        </div>
    )
}

export default Tng