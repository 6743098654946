import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTSVG } from '../../../../_metronic/helpers';
import Moment from 'react-moment'
import clsx from 'clsx';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Carousel } from 'react-bootstrap';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Item1 } from '../../../../_metronic/partials/content/activity/Item1';

const API_URL = process.env.REACT_APP_API_URL

type Data = {
    id: number;
    name: string;
    category: string;
    event: string;
    eventsub: string;
    freq: string;
    date_start: string;
    date_end: string;
    mode: string;
    region: string;
    thrust: string;
    location: string;
    country: string;
    mo: string;
    speaker: string;
    regist_att: number;
    volun: number;
    onsite_att: number;
    online_att: number;
    onsite_salv: number;
    online_salv: number;
    summary: string;
}

type Photos = {
    path: string;
}



const ListEventDetail = () => {

    const [list, setList] = useState<Data[]>([]);
    const [photos, setPhotos] = useState<Photos[]>([]);


    const { state } = useLocation();

    useEffect(() => {

        if (state != null) {

            let url = `event/get-event/${state}`

            axios.get(`${API_URL}${url}`).then((res) => {
                setList(res.data.data.data)
                setPhotos(res.data.data.photo)


            }).catch(error => console.error(`Error:${error}`));
        }

    }, []);




    return (
        <div>

            <KTCard>
                <div className='card-header border-0 py-10'>
                    <div className='card-title'>
                        {list.map(k => k.name)}
                    </div>

                </div>

                {photos.length > 0 && (

                    <div>

                        <div className='separator mb-3 opacity-75'></div>
                        <KTCardBody className='py-4'>

                            <div className='d-flex flex-column flex-xl-row flex-row-fluid'>
                                <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-100 h-600px w-xxl-100 me-9'>
                                    <Carousel slide={false} data-interval="false">
                                        {photos.map((value, index) => {

                                            return (
                                                <Carousel.Item key={index}>
                                                    <img
                                                        className="d-block h-600px w-100"
                                                        src={API_URL + 'file/get-image/' + value.path}
                                                        alt="First slide" />

                                                </Carousel.Item>
                                            );
                                        })}

                                    </Carousel>
                                </div>
                            </div>

                        </KTCardBody>

                    </div>



                )}


            </KTCard>
            <br></br>

            <KTCard>
                <KTCardBody>
                    <div className='card-body p-9'>

                        <div className='row'>
                            <div dangerouslySetInnerHTML={{ __html: String(list.map(k => k.summary)) }} />
                        </div>



                    </div>
                </KTCardBody>

            </KTCard>


            <br></br>

            <KTCard>
                <KTCardBody>
                    <div className='card-body p-9'>
                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>Category</label>

                            <div className='col-lg-8'>
                                <span className='fw-bolder fs-6 text-dark'>{list.map(k => k.category)}</span>
                            </div>
                        </div>

                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>Event</label>
                            <div className='col-lg-8 fv-row'>
                                <span className='fw-bold fs-6'>{list.map(k => k.event)}</span>
                            </div>
                        </div>


                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>Sub Event</label>
                            <div className='col-lg-8 fv-row'>
                                <span className='fw-bold fs-6'>{list.map(k => k.eventsub)}</span>
                            </div>
                        </div>

                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>Date Held</label>
                            <div className='col-lg-8 fv-row'>
                                <span className='fw-bold fs-6'>{list.map(k => k.date_start)} - {list.map(k => k.date_end)}</span>
                            </div>
                        </div>

                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>Registered Attendee</label>
                            <div className='col-lg-8 fv-row'>
                                <span className='fw-bold fs-6'>{list.map(k => k.regist_att)}</span>
                            </div>
                        </div>

                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>Volunteers</label>
                            <div className='col-lg-8 fv-row'>
                                <span className='fw-bold fs-6'>{list.map(k => k.volun)}</span>
                            </div>
                        </div>


                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>Mode</label>
                            <div className='col-lg-8 fv-row'>
                                <span className='fw-bold fs-6'>{list.map(k => k.mode)}</span>
                            </div>
                        </div>


                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>
                                Attendee
                            </label>

                            <div className='col-lg-8 d-flex align-items-center'>
                                <div className='me-10'>
                                    <span className='fw-bolder fs-6 me-2'>{list.map(k => k.onsite_att)}</span>
                                    <span className='badge badge-info'>Onsite</span>

                                </div>

                                <div className='me-10'>
                                    <span className='fw-bolder fs-6 me-2'>{list.map(k => k.online_att)}</span>
                                    <span className='badge badge-success'>Online</span>

                                </div>
                            </div>
                        </div>


                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>
                                Salvation
                            </label>

                            <div className='col-lg-8 d-flex align-items-center'>
                                <div className='me-10'>
                                    <span className='fw-bolder fs-6 me-2'>{list.map(k => k.onsite_salv)}</span>
                                    <span className='badge badge-info'>Onsite</span>

                                </div>

                                <div className='me-10'>
                                    <span className='fw-bolder fs-6 me-2'>{list.map(k => k.online_salv)}</span>
                                    <span className='badge badge-success'>Online</span>

                                </div>
                            </div>
                        </div>

                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>Location | Country</label>
                            <div className='col-lg-8 fv-row'>
                                <span className='fw-bold fs-6'>{list.map(k => k.location)} |  {list.map(k => k.country)}</span>
                            </div>
                        </div>



                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>MO</label>
                            <div className='col-lg-8 fv-row'>
                                <span className='fw-bold fs-6'>{list.map(k => k.mo)}</span>
                            </div>
                        </div>

                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>Speaker</label>
                            <div className='col-lg-8 fv-row'>
                                <span className='fw-bold fs-6'>{list.map(k => k.speaker)}</span>
                            </div>
                        </div>


                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>Region</label>
                            <div className='col-lg-8 fv-row'>
                                <span className='fw-bold fs-6'>{list.map(k => k.region)}</span>
                            </div>
                        </div>

                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>Thrust</label>
                            <div className='col-lg-8 fv-row'>
                                <span className='fw-bold fs-6'>{list.map(k => k.thrust)}</span>
                            </div>
                        </div>



                    </div>
                </KTCardBody>

            </KTCard>


        </div>
    )
}

export default ListEventDetail