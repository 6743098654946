import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import Monthly from './CombinedAverage'
import { ReportHeader } from './ReportHeader'
import Worship from './Worship'
import Tng from './Tng'
import CombinedAverage from './CombinedAverage'
import MonthlyAttendance from './MonthlyAttendance'
import AttendanceTrend from './AttendanceTrend'


const chatBreadCrumbs: Array<PageLink> = [
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
type Props = {
    read: boolean
    write: boolean
  }
  
const ReportPageCombined: React.FC<Props> = ({ read, write }) => {
  return (
    <Routes>
      <Route element={
          <>
            <ReportHeader />
            <Outlet />
          </>
        }
      >
        <Route
          path='worshipdetails'
          element={
            <>
              <PageTitle>Worship History</PageTitle>
              <Worship/>
         
       
            </>
          }
        />
        <Route
          path='tngdetails'
          element={
            <>
              <PageTitle>TNG History</PageTitle>
              <Tng/>
         
       
            </>
          }
        />
        <Route
          path='combinedaverage'
          element={
            <>
              <PageTitle>Combined Average Attendances</PageTitle>
              <CombinedAverage/>
      
            </>
          }
        />
        
        <Route
          path='monthlyattendance'
          element={
            <>
              <PageTitle>Monthly Attendance</PageTitle>
              <MonthlyAttendance/>
         
       
            </>
          }
        />
        <Route
          path='attendancetrend'
          element={
            <>
              <PageTitle>Attendance Trend</PageTitle>
              <AttendanceTrend/>
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ReportPageCombined;