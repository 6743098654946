import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import ListEvent from './ListEvent'
import ListEventDetail from './ListEventDetail'


const chatBreadCrumbs: Array<PageLink> = [
  {
    title: 'Report',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
type Props = {
    read: boolean
    write: boolean
  }
  
const EventReportPage: React.FC<Props> = ({ read, write }) => {
  return (
    <Routes>
      <Route element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='listevent'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Event</PageTitle>
              <ListEvent/>
          
            </>
          }
        />
        <Route
          path='eventdetail'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Event</PageTitle>
              <ListEventDetail/>
      
            </>
          }
        />
      
      </Route>
    </Routes>
  )
}

export default EventReportPage
