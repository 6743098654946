import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { boolean } from 'yup'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { WorshipHeader } from './WorshipHeader'
import WorshipRegistration from './WorshipRegistration'
import WorshipWeekly from './WorshipWeekly'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

type Props = {
  read: boolean
  write: boolean
}

const WorshipPage: React.FC<Props> = ({ read, write }) => {

  return (
    <Routes>
      <Route
        element={
          <>
            <WorshipHeader />
            <Outlet />
          </>
        }
      >

        {write ? (
          <Route
            path='registration'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Worship Data Entry</PageTitle>

                <WorshipRegistration />

              </>
            }
          />

        ) : (

          <Route path='*' element={<Navigate to='/error/404' />} />
        )}

        {read ? (

          <Route
            path='weekly'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Weekly Attendance</PageTitle>
                <WorshipWeekly />

              </>
            }
          />

        ) : (
          <Route path='*' element={<Navigate to='/error/404' />} />
        )

        }

        <Route index element={<Navigate to='/module/worship/registration' />} />
      </Route>
    </Routes>
  )
}

export default WorshipPage
