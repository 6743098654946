import { lazy, FC, Suspense, useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import WorshipPage from '../modules/workship/WorshipPage'
import TngPage from '../modules/tng/TngPage'
import { useAuth } from '../modules/auth'
import axios from 'axios';
import EventPage from '../modules/events/EventPage'
import SettingsPage from '../modules/apps/user-management/user-settings/SettingsPage'
import EventReportPage from '../modules/Report/Events/EventReportPage'
import ReportPageCombined from '../modules/Report/CombinedReport/ReportPageCombined'
const API_URL = process.env.REACT_APP_API_URL

type Data = {
  menu: string;
  read: boolean;
  write: boolean;
  roles_id: number
}

interface UserAccess {
  roles_id: number
  menu: string
  read: boolean | false
  write: boolean | false
}


const PrivateRoutes = () => {
  const { currentUser } = useAuth()

  const [accessUsers, setAccessUsers] = useState<UserAccess[]>([]);



  useEffect(() => {

    axios.get(`${API_URL}user/get-current-user-access`).then((res) => {
      setAccessUsers(res.data)
    }).catch(error => console.error(`Error:${error}`));


  }, []);


  const SystemPage = lazy(() => import('../modules/apps/system/SystemPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />



        {accessUsers.filter(menu => menu.menu == 'Worship').map((item, index) => {
          return (
            item.menu == 'Worship' ? (
              <Route key={index}
                path='module/worship/*'
                element={
                  <SuspensedView>
                    <WorshipPage read={item.read} write={item.write} />
                  </SuspensedView>
                }
              />
            ) : (
              <Route key={index} path='*' element={<Navigate to='/error/404' />} />
            )

          );
        }
        )}




        {accessUsers.filter(menu => menu.menu == 'TNG').map((item, index) => {
          return (
            item.menu == 'TNG' ? (
              <Route key={index}
                path='module/TNG/*'
                element={
                  <SuspensedView>
                    <TngPage read={item.read} write={item.write} />
                  </SuspensedView>
                }
              />
            ) : (
              <Route key={index} path='*' element={<Navigate to='/error/404' />} />
            )

          );
        })}




        {accessUsers.filter(menu => menu.menu == 'Events').map((item, index) => {
          return (
            item.menu == 'Events' ? (
              <Route key={index}
                path='module/event/*'
                element={
                  <SuspensedView>
                    <EventPage read={item.read} write={item.write} />
                  </SuspensedView>
                }
              />
            ) : (
              <Route key={index} path='*' element={<Navigate to='/error/404' />} />
            )

          );
        })}

        {accessUsers.filter(menu => menu.menu == 'Reports').map((item, index) => {
          return (
            item.menu == 'Reports' ? (
              <Route key={index}
                path='module/combinedreport/*'
                element={
                  <SuspensedView>
                    <ReportPageCombined read={item.read} write={item.write} />
                  </SuspensedView>
                }
              />
            ) : (
              <Route key={index} path='*' element={<Navigate to='/error/404' />} />
            )

          );
        })}

        {accessUsers.filter(menu => menu.menu == 'Reports').map((item, index) => {
          return (
            item.menu == 'Reports' ? (
              <Route key={index}
                path='module/eventreport/*'
                element={
                  <SuspensedView>
                    <EventReportPage read={item.read} write={item.write} />
                  </SuspensedView>
                }
              />
            ) : (
              <Route key={index} path='*' element={<Navigate to='/error/404' />} />
            )

          );
        })}





        {accessUsers.filter(menu => menu.menu == 'System').map((item, index) => {
          return (
            item.menu == 'System' ? (
              <Route key={index}
                path='apps/system/*'
                element={
                  <SuspensedView>
                    <SystemPage />
                  </SuspensedView>
                }
              />
            ) : (
              <Route key={index} path='*' element={<Navigate to='/error/404' />} />
            )

          );
        })}


         {accessUsers.filter(menu => menu.menu == 'System').map((item, index) => {
          return (
            item.menu == 'System' ? (
              <Route key={index}
                path='apps/system/*'
                element={
                  <SuspensedView>
                    <SystemPage />
                  </SuspensedView>
                }
              />
            ) : (
              <Route key={index} path='*' element={<Navigate to='/error/404' />} />
            )

          );
        })}



        {accessUsers.filter(menu => menu.menu == 'User').map((item, index) => {
          return (
            item.menu == 'User' ? (
              <Route key={index}
                path='apps/user-management/*'
                element={
                  <SuspensedView>
                    <UsersPage />
                  </SuspensedView>
                }
              />
            ) : (
              <Route key={index} path='*' element={<Navigate to='/error/404' />} />
            )

          );
        })}

        <Route
          path='apps/account/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />


        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
