import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'

const ReportHeader: React.FC = () => {
  const location = useLocation()

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
     

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    Worship and TNG Attendance Reports 
                  </a>
                </div>

        
              </div>

            </div>

          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>

            <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/module/combinedreport/combinedaverage' && 'active')
                }
                to='/module/combinedreport/combinedaverage'
              >
                Combined Average Attendances
              </Link>


             
            </li>
            <li className='nav-item'>
            <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/module/combinedreport/monthlyattendance' && 'active')
                }
                to='/module/combinedreport/monthlyattendance'
              >
                Monthly Attendance
              </Link>
            </li>
            <li className='nav-item'>
            <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/module/combinedreport/attendancetrend' && 'active')
                }
                to='/module/combinedreport/attendancetrend'
              >
                Attendance Trend
              </Link>
            </li>
            <li className='nav-item'>
            <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/module/combinedreport/worshipdetails' && 'active')
                }
                to='/module/combinedreport/worshipdetails'
              >
                Worship History
              </Link>
            </li>
            <li className='nav-item'>
            <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/module/combinedreport/tngdetails' && 'active')
                }
                to='/module/combinedreport/tngdetails'
              >
                TNG History
              </Link>
            </li>
           

          </ul>
        </div>
      </div>
    </div>
  )
}

export {ReportHeader}
