import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import { KTSVG } from '../../../_metronic/helpers'
import Utilities from '../../services/Utilities'
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import Moment from 'react-moment'
import { Button, Modal } from 'react-bootstrap';
const API_URL = process.env.REACT_APP_API_URL


type Worship = {
    id:number;
    date: string;
    servicetype_id: number;
    servicetype: string;
    centre_id: number;
    center: string;
    service_id: number;
    service: string;
    timing_id: number;
    timing: string;
    onsite_att: number | 0;
    online_att: number | 0;
    first_timers: number | 0;
    salvations: number | 0;
    created_at: string;
    updated_at: string;
    created_by: string;
}

const WorshipWeekly = () => {

    const [data, setData] = useState<Worship[]>([]);
    const [loading, setLoading] = useState(false)
    const { toasts } = Utilities();
    const { state } = useLocation();

    const [optSmModal, setOptSmModal] = useState(false);
    const toggleShowSm = () => setOptSmModal(!optSmModal);

    useEffect(() => {
        const history = createBrowserHistory();
        history.replace('', null)

        getData()

        if (state) {
            toasts("succes", "Data Updated Successfully !");
        }

    }, []);


    function getData() {
        axios.get(`${API_URL}worship/get-weekly-worship`).then((res) => {
            setData(res.data.data);
        }).catch(error => console.error(`Error:${error}`));
    }


    const navigate = useNavigate();
    const gotoEdit = (id: number) => {
        navigate('/module/worship/registration',{ state:  id});
    }
    const [id, setId] = useState(0)
    const gotoDel = (id: number) => {
        setId(id)
        toggleShowSm()

    }

    const deleteData = () => {
        let data = {
            id: id
        }
        setLoading(true)
        axios.delete(`${API_URL}worship/delete-worship`, { data }).then((res) => {
            if (res.data.data === true) {
                toasts("success", "Data Deleted Successfully !");
                getData()
                setLoading(false)
                toggleShowSm()
            }else{
                setLoading(false)
                toggleShowSm()
                toasts("error", "Cannot delete data, You are not the owner !");
            }
        }).catch(error => console.error(`Error:${error}`));

    }

    const ModalConfirm = () => {
        return (
            <>
                <Modal show={optSmModal} onHide={toggleShowSm} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure want to delete the data ?</Modal.Body>
                    <Modal.Footer>

                        <button className='btn btn-danger' onClick={() => deleteData()} disabled={loading}>{!loading && 'Confirm'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}</button>
                        <Button variant="secondary" onClick={toggleShowSm}>
                            Cancel
                        </Button>

                    </Modal.Footer>
                </Modal>
            </>
        )
    }


    return (
        <>
            <ToastContainer theme={"colored"} />
            <ModalConfirm />
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Weekly Attendance Data</h3>
                    </div>
                </div>

                <div id='kt_account_profile_details' className='collapse show'>
                    <div className='card-body border-top p-9'>
                        {/* begin::Header */}
                        <div className='card-header border-0'>
                            <h3 className='card-title align-items-start flex-column'>

                            </h3>
                            <div className='card-toolbar'>


                            </div>
                        </div>
                        <div className='card-body py-3'>
                            <div className='table-responsive'>
                                <table className='align-middle table table-row-bordered gy-7 gs-7'>
                                    <thead>
                                        <tr className='fw-bold bg-light text-muted fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                                            <th className='ps-4 min-w-100px rounded-start'>Date</th>
                                            <th className='min-w-125px'>Service Type</th>
                                            <th className='min-w-125px'>Centre</th>
                                            <th className='min-w-125px'>Service</th>
                                            <th className='min-w-125px'>Timing</th>
                                            <th className='min-w-100px'>Attendee</th>
                                            <th className='min-w-100px'>Newcomer</th>
                                            <th className='min-w-100px'>Salvations</th>
                                            <th className='min-w-100px'>Created By</th>
                                            <th className='min-w-100px'>Updated At</th>
                                            <th className='min-w-100px'>Created At</th>
                                            <th className='min-w-200px text-end rounded-end'></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {data.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.date}</td>
                                                    <td>{item.servicetype}</td>
                                                    <td>{item.center}</td>
                                                    <td>{item.service}</td>
                                                    <td>{item.timing}</td>
                                                    <td>{item.onsite_att}</td>
                                                    <td>{item.first_timers}</td>
                                                    <td>{item.salvations}</td>
                                                    <td>{item.created_by}</td>
                                                    <td>{item.updated_at!=null && <Moment format="DD-MM-YYYY HH:mm">{item.updated_at}</Moment>}</td>
                                                    <td><Moment format="DD-MM-YYYY HH:mm">{item.created_at}</Moment></td>
                                                    <td className='text-end'>
                                                        <a onClick={() => gotoEdit(item.id)}
                                                            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'>
                                                            Edit
                                                        </a>
                                                        <a onClick={() => gotoDel(item.id)}
                                                            className='btn btn-bg-danger btn-color-white btn-active-color-primary btn-sm px-4'>
                                                            Delete
                                                        </a>
                                                    </td>
                                                    
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default WorshipWeekly

