import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ToastContainer } from 'react-toastify'
import { number } from 'yup'
import { KTSVG } from '../../../_metronic/helpers'
import Utilities from '../../services/Utilities'
import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import Moment from 'react-moment';
import { Button, Modal } from 'react-bootstrap';
const API_URL = process.env.REACT_APP_API_URL


type Tng = {
    id: number;
    date: string;
    centre_id: number;
    center: string;
    timing_id: number;
    timing: string;
    level_id: number;
    level: string;
    onsite_att: number | 0;
    online_att: number | 0;
    parent_server: number | 0;
    salvation: number | 0;
    adult_dmk: number | 0;
    youth_dmk: number | 0;
    created_at: string;
    updated_at:string;
    created_by:string
}

const TngWeekly = () => {

    const [data, setData] = useState<Tng[]>([]);
    const [loading, setLoading] = useState(false)
    const { toasts } = Utilities();
    const { state } = useLocation();

    const [optSmModal, setOptSmModal] = useState(false);
   const toggleShowSm = () => setOptSmModal(!optSmModal);

    useEffect(() => {
        const history = createBrowserHistory();
        history.replace('', null)

        if(state){
            toasts("success", "Data Updated Successfully !");
        }

        getData()


    }, []);

    function getData(){
        axios.get(`${API_URL}tng/get-weekly-tng`).then((res) => {
            setData(res.data.data);
        }).catch(error => console.error(`Error:${error}`));
    }



        const navigate = useNavigate();
        const gotoEdit=(id:number)=>
        {
            navigate('/module/tng/tngregistration', { state:  id});

        }
        const[id,setId]=useState(0)
        const gotoDel=(id:number)=>
        {
            setId(id)
            toggleShowSm()

        }

        const gotoAddlInfo=(id:number)=>
        {
            navigate('/module/tng/tngaddlinfo', { state:  id});

        }

        const deleteData=()=>{
            let data={
                id:id
            }
            setLoading(true)
            axios.delete(`${API_URL}tng/delete-tng`,{data}).then((res) => {
                if (res.data.data === true) {
                    toasts("success", "Data Deleted Successfully !");
                    getData()
                    setLoading(false)
                    toggleShowSm()
                }else{
                    setLoading(false)
                    toggleShowSm()
                    toasts("error", "Cannot delete data, You are not the owner !");
                }
            }).catch(error => console.error(`Error:${error}`));

        }

        const ModalConfirm=()=>{
            return (
              <>
               <Modal show={optSmModal} onHide={toggleShowSm} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure want to delete the data ?</Modal.Body>
                    <Modal.Footer>

                        <button className='btn btn-danger' onClick={() => deleteData()} disabled={loading}>{!loading && 'Confirm'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}</button>
                        <Button variant="secondary" onClick={toggleShowSm}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
              </>
            )
          }


    return (
        <>
        <ToastContainer theme={"colored"} />
        <ModalConfirm/>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>TNG Attendance</h3>
                    </div>
                </div>

                <div id='kt_account_profile_details' className='collapse show'>
                    <div className='card-body border-top p-9'>
                        {/* begin::Header */}
                        <div className='card-header border-0'>
                            <h3 className='card-title align-items-start flex-column'>
                                
                            </h3>
                            <div className='card-toolbar'>
                            </div>
                        </div>
                        <div className='card-body py-3'>
                            <div className='table-responsive'>
                                <table className='align-middle table table-row-bordered gy-7 gs-7'>
                                    <thead>
                                    <tr className='fw-bold bg-light text-muted fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                                            <th className='ps-4 min-w-100px rounded-start'>Date</th>
                                            <th className='min-w-125px'>Centre</th>
                                            <th className='min-w-125px'>Level</th>
                                            <th className='min-w-125px'>Timing</th>
                                            <th className='min-w-100px'>Onsite Attendance</th>
                                            <th className='min-w-100px'>Online Attendance</th>
                                            <th className='min-w-100px'>Parent Server</th>
                                            <th className='min-w-100px'>Salvation</th>
                                            <th className='min-w-100px'>Adult DMK</th>
                                            <th className='min-w-100px'>Youth DMK</th>
                                            <th className='min-w-100px'>Created By</th>
                                            <th className='min-w-100px'>Updated At</th>
                                            <th className='min-w-100px'>Created At</th>
                                            <th className='min-w-200px text-end rounded-end'></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {data.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.date}</td>
                                                    <td>{item.center}</td>
                                                    <td>{item.level}</td>
                                                    <td>{item.timing}</td>
                                                    <td>{item.onsite_att}</td>
                                                    <td>{item.online_att}</td>
                                                    <td>{item.parent_server}</td>
                                                    <td>{item.salvation}</td>
                                                    <td>{item.adult_dmk}</td>
                                                    <td>{item.youth_dmk}</td>
                                                    <td>{item.created_by}</td>
                                                    <td>{item.updated_at!=null && <Moment format="DD-MM-YYYY HH:mm">{item.updated_at}</Moment>}</td>
                                                    <td><Moment format="DD-MM-YYYY HH:mm">{item.created_at}</Moment> </td>
                                                    <td className='text-end'>
                                                        <div className='btn-group' role='group'>
                                                            <button onClick={()=>gotoEdit(item.id)}
                                                                className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                                                style={{ display: 'inline-flex', whiteSpace: 'nowrap' }}
                                                                >
                                                                Edit
                                                            </button>
                                                            <button onClick={()=>gotoAddlInfo(item.id)}
                                                                className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                                                style={{ display: 'inline-flex', whiteSpace: 'nowrap' }}
                                                                >
                                                                Edit Add'l Info
                                                            </button>
                                                            <button onClick={()=>gotoDel(item.id)}
                                                                className='btn btn-bg-danger btn-color-white btn-active-color-primary btn-sm px-4'
                                                                style={{ display: 'inline-flex', whiteSpace: 'nowrap' }}
                                                                >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                    {/* end::Table body */}
                                </table>
                                {/* end::Table */}
                            </div>
                            {/* end::Table container */}
                        </div>
                        {/* begin::Body */}
                    </div>

                </div>
            </div>
        </>
    )
}

export default TngWeekly

