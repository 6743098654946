/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { TablesWidget9 } from './widget/TablesWidget9'
import { RecentData } from './widget/RecentData'
const { tableau } = window;
const API_URL = process.env.REACT_APP_API_URL

type DashboardWorship = {
  services_headers: Array<string>
  timing_headers: Array<string>
  centre_headers: Array<string>
  services_total: Array<ServicesTotal>
  data: Center
}

type DashboardTng = {
  timing_headers: Array<string>
  centre_headers: Array<string>
  services_total: Array<ServicesTotal>
  levels_headers: Array<string>
  data: Center
}

type ServicesTotal = {
  'centre': string
  'chart_data': Array<number>
}

type Center = {
  'Woodlands': Services
  'Covenant East @ Suntec': Services
  'Bukit Panjang': Services
}

type Services = {
  English? : Timing,
  Mandarin?: Timing,
  Hokkien?: Timing,
  Indonesian?: Timing,
  Filipino?: Timing,
  Tamil?: Timing,
  "China Nationals"?: Timing,
  Total?: Timing
}

type Timing = {
  "First Service"?: Attendance,
  "Second Service"?: Attendance,
}

type Attendance = {
  attendance: number,
  special: boolean
}

type Event = {
  id: number;
  name: string;
  category: string;
  event: string;
  eventsub: string;
  freq: string;
  date_start: string;
  date_end: string;
  mode: string;
  speaker: string;
  regist_att: number;
  onsite_att: number;
  online_att: number;
}


const DashboardWrapper: FC = () => {


  const [viz, setViz] = useState<any | null>(null);
  const [disabledState, setDisabled] = useState("disabled");
  const [dataevent, setDataevent] = useState<Event[]>([]);

  const [recentWorships, setRecentWorships] = useState<DashboardWorship>();
  const [recentTngs, setRecentTngs] = useState<DashboardTng>();


  const intl = useIntl()
 

  useEffect(() => {

    axios.get(`${API_URL}dashboard/get-recent-worships`).then((res) => {
      setRecentWorships(res.data)
    }).catch(error => console.error(`Error:${error}`));

    axios.get(`${API_URL}dashboard/get-recent-tngs`).then((res) => {
      setRecentTngs(res.data)
    }).catch(error => console.error(`Error:${error}`));

    axios.get(`${API_URL}dashboard/get-recent-events`).then((res) => {
      setDataevent(res.data);
    }).catch(error => console.error(`Error:${error}`));


    axios.get(`${API_URL}tableau/get-all-tableau-link`).then((res) => {
  
      if(res.data.data.length>0){

        let url=res.data.data[0]['url_tableau']


        let options = {
          hideTabs: true,
          hideToolbar: true,
          height: '1000px',
          allowFullScreen: true,
          width: '100%',
          onFirstInteractive: () => {
            setDisabled("");
          }
        };
        let containerDiv = document.getElementById("container");
        let el1: HTMLElement = containerDiv!;
        setViz(new tableau.Viz(el1, url, options));
        
      }
    }).catch(error => console.error(`Error:${error}`));


  }, [])


  const setVizStyle = {
  margin: "25px",
  width: "600px",
  height: "650px"
};


// rgba(0, 136, 220, 0.9), rgba(52, 61, 235, 0.9),rgba(52, 61, 235, 0.9),
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-12'>
          <RecentData title='Worship' data={recentWorships?.data} timing_headers={recentWorships?.timing_headers} services_headers={recentWorships?.services_headers}
            centre_headers={recentWorships?.centre_headers} className='card-xl-stretch mb-xl-8' services_total={recentWorships?.services_total}
            color='linear-gradient(to right,rgba(72,164,244, 1), rgba(8, 151, 212, 0.7), rgba(72,116,244, 1), rgba(72,116,244, 1), rgba(128, 52, 235, 0.8))'
          />
        </div>
      </div>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-12'>
          <RecentData title='Tng' data={recentTngs?.data} timing_headers={recentTngs?.timing_headers} services_headers={recentTngs?.levels_headers}
            centre_headers={recentTngs?.centre_headers} className='card-xl-stretch mb-xl-8' services_total={recentTngs?.services_total}
            color='linear-gradient(to right, rgba(248,28,68, 0.9), rgba(235, 52, 89, 0.6),rgba(248,28,68, 0.9),rgba(248,28,68, 0.9), rgba(161, 3, 3, 0.9))'
          />
        </div>
      </div>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-12'>
          <TablesWidget9 event={dataevent} className='mb-5 mb-xl-8' />
        </div>
      </div>
    

      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-12'>

          <div id="container" />
         
        </div>


      </div>

    </>
  )
}

export { DashboardWrapper }

export type { ServicesTotal }