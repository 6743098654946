/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'


type Props = {
    className: string,
    event: Event[]
}


type Event = {
    id: number;
    name: string;
    category: string;
    event: string;
    eventsub: string;
    freq: string;
    date_start: string;
    date_end: string;
    mode: string;
    speaker: string;
    regist_att: number;
    onsite_att: number;
    online_att: number;
}

const TablesWidget9: React.FC<Props> = ({ className, event }) => {

    return (
        <div className={`card ${className} bg-gray-100 align-items-center`}>
        <div className='card-body p-0 row'>
          <div className={`px-9 pt-7 h-275px w-100`} style={{ background: 'linear-gradient(to right, rgb(56,189,105), rgb(134,225,130),rgb(56,189,105, 0.9),rgb(44,156,68, 0.7),rgb(56,189,105, 0.8), rgb(38,138,48))' }}>
            <div className='d-flex flex-stack'>
              <h3 className='m-0 text-white fw-bold fs-3' >Recent Events</h3>
              <div className='ms-1'>         
              </div>
            </div>
          </div>
          <div className={`col-12 px-8 mr-5`} style={{ marginTop: '-200px' }}>
            {/* begin::Table */}
            <table 
            className='table table-row-dashed table-hover square bg-white rounded-2'
            style={{overflow:'hidden'}}
            >
                {/* begin::Table head */}
                <thead className='bg-gray-300'>
                    <tr className='fw-bold text-bold'>
                        <th style={{ textAlign: 'center', width: '45%' }}>Event</th>
                        <th style={{ textAlign: 'center', width: '25%' }}>Type</th>
                        <th style={{ textAlign: 'center', width: '15%' }}>Mode</th>
                        <th style={{ textAlign: 'center', width: '15%' }}>Date</th>
                    </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                    {event.map((item, index) => {
                        return (

                            <tr key={index}>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <div className='symbol symbol-45px me-5'>

                                        </div>
                                        <div className='d-flex justify-content-start flex-column'>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                {item.name}
                                            </a>
                                            <span className='d-block fs-7'>
                                                {item.category}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                        {item.event} {item.eventsub}
                                    </a>
                                    <span className='d-block fs-7'>
                                        {item.freq}
                                    </span>
                                </td>
                                <td className='text-end'>
                                    <div className='d-flex flex-column w-100 me-2'>
                                        <div className='d-flex flex-stack mb-2'>
                                            <span className='me-2 fs-7 fw-bold text-dark'>{item.online_att + item.onsite_att}</span>
                                        </div>
                                        <div className='d-flex flex-stack mb-2'>

                                            {item.mode}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex justify-content-start flex-shrink-0'>

                                        <div className='d-flex justify-content-start flex-column'>

                                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                {item.date_start}
                                            </a>
                                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                {item.date_end}
                                            </a>


                                        </div>




                                    </div>
                                </td>
                            </tr>


                        );
                    })}



                </tbody>
                {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        </div>
       </div>
    )
}

export { TablesWidget9 }
