import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { KTCard, KTCardBody } from '../../../../_metronic/helpers';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/esm/Spinner';

const API_URL = process.env.REACT_APP_API_URL

type MonthlyAttendanceData = {
    attendances: number[];
    service: string;
    special: boolean[];
    num_additions: number[];
    timing: string | null;
    average: number;
  };

function sumArray(arr: number[]): number {
    return arr.reduce((acc, value) => acc + value, 0);
  }

const MonthlyAttendance = () => {
    const [loading, setLoading] = useState(false);
    const [selectedYear, setSelectedYear] = useState<Date | null>(new Date());
    const [excludeSpecialServices, setExcludeSpecialServices] = useState<boolean>(false);
    const [tableData, setTableData] = useState<Array<MonthlyAttendanceData>|null>([]);
    const [dateData, setDateData] = useState<[]|null>([]);
    const [totalData, setTotalData] = useState<Array<number>>([]);
    const [showAverage, setShowAverage] = useState(false);
    const [totalAverage, setTotalAverage] = useState(0);
    const handleCheckboxChange = () => {
      setExcludeSpecialServices(!excludeSpecialServices);
    };

    const monthNames: string[] = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    const [centres, setCentres] = useState([]);
    const [selectedCentre, setSelectedCentre] = useState<string>('Bukit Panjang');
    const currentMonth = new Date().getMonth() + 1;
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);

    useEffect(() => {
        applyFilter();
    }, []);



    const applyFilter = () => {
        try {
            axios.get(`${API_URL}center/get-all-centers`).then((res) => {
                let result = res.data.data;
                let resultCentres = result.map((item: any) => {
                    return item.name;
                })
                setCentres(resultCentres);
                }).catch(error => console.error(`Error:${error}`));
            
            let params: any;
            if (selectedCentre) {
                params = {
                    params: {
                      exclude_special: excludeSpecialServices ? "1": "",
                      year: selectedYear?.getFullYear(),
                      month: selectedMonth,
                      centre: selectedCentre
                    },
                  }
            } else {
                params = {
                    params: {
                      exclude_special: excludeSpecialServices ? "1" : "",
                      year: selectedYear?.getFullYear(),
                      month: selectedMonth
                    },
                  };
            }
            setLoading(true);
            axios.get(`${API_URL}combined-report/get-weekly-attendance`,
            params
            ).then((res) => {
                let total = new Array(res.data.data[0].attendances.length + 1).fill(0);
                res.data.data?.map((rowData: { attendances: any[]; num_service: number; }, index: number) => {
                    rowData.attendances.map((item, index) => {
                        total[index] += item;
                    });
                })
                total[total.length-1] = sumArray(total);
                setTotalData(total);
                setShowAverage(excludeSpecialServices);
                setDateData(res.data.dates);
                setTableData(res.data.data);
                setLoading(false);
                setTotalAverage(res.data.total_avg);
            }).catch(error => console.error(`Error:${error}`));
          } catch (error) {
            console.error('Error applying filter:', error);
            setLoading(false);
          }
    }




    const MonthlyAttendanceTable = () => {

        return (

            <table
                id='kt_table_users'
                className='align-middle table table-row-bordered gy-7 gs-7'>
                <thead className='fw-bold bg-light text-muted fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                    <tr>
                        <th key={0}>Services</th>
                        <th key={1}>Timing</th>
                        {dateData?.map((item, x) => {
                            return (
                                <th key={x+2}>{item}</th>
                            )

                        })}
                        <th key={'total_header'}>Total</th>
                        {showAverage ? 
                            (<th key={'average_header'}>Average</th>) : null
                        }   
                    </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold'>
                    {tableData?.map((rowData, n) => {
                        return (
                            <tr key={'row ' + n}>
                                <td key={0}>{rowData.service}</td>
                                <td key={1}>{rowData.timing}</td>

                                {rowData.attendances.map((item, index) => {
                                    if (rowData.special[index]) {
                                        return (
                                            <td key={index+2}>{item}*</td>
                                        ) 
                                    }
                                    return (
                                        <td key={index+2}>{item}</td>
                                    ) 
                                })}
                                
                                <td key={'total_' + n}>{sumArray(rowData.attendances)}</td>
                                {showAverage ? (<td key={'average_' + n}>{rowData.average}</td>) : null}
                            </tr>
                        );
                    })}
                    <tr>
                        <th className='fw-bold fs-6 text-gray-800'>Total</th>
                        <th className='fw-bold fs-6 text-gray-800'></th>
                        {totalData.map((item, index) => {
                            return (
                                <th key={'col-total-' + index}>
                                    {Math.round(item * 100) / 100}
                                </th>
                            );
                        })}
                        {showAverage ? (<th key={'col-average'}>
                                    {Math.round(totalAverage * 100) / 100}
                                </th>) : null}
                    </tr>
                </tbody>
            </table>
        )

    }

    return (
        <div>
            <div className='card mb-5 mb-xl-10'>
                <KTCard>
                    <div className='card-header border-0 pt-6'>
                        <div className='card-title mx-2 p-0 mb-6'>
                            <h3 >Monthly Attendance</h3>
                        </div>
                    </div>
                        <div className='card-toolbar'>
                            <div className=' justify-content-start mx-9'>
                                <label className='mb-2'>
                                    <h4 className='fw-bold text-muted mx-2'>Year</h4>
                                    <DatePicker className="form-control w-250px me-2 col-ms-12"
                                        showYearPicker
                                        dateFormat="yyyy" selected={selectedYear} onChange={(year) => setSelectedYear(year)} name="date" />
                                </label>
                                <label className='mx-2 mt-2'>
                                    <h4 className='fw-bold text-muted mx-2'>Month</h4>
                                    <select defaultValue={selectedMonth} onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                                    
                                    className='form-select fw-bolder me-2'
                                    data-kt-select2='true'
                                    data-placeholder='Select option'
                                    data-allow-clear='true'
                                    data-kt-user-table-filter='role'
                                    data-hide-search='true'
                                >
                                    {monthNames.map((item, index) => {
                                            return (
                                                <option key={1 + index} value={1 + index}>{item}</option>
                                            )
                                    })} 

                                </select>
                                </label>
                                <label className='mx-2'>
                                    <h4 className='fw-bold text-muted mx-2'>Centre</h4>
                                <select onChange={(e) => setSelectedCentre(e.target.value)}
                                    className='form-select fw-bolder me-2'
                                    data-kt-select2='true'
                                    data-placeholder='Select option'
                                    data-allow-clear='true'
                                    data-kt-user-table-filter='role'
                                    data-hide-search='true'
                                >
                                    {centres.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            );
                                        })}
                                </select>
                                </label>
                                <label className='mx-2'>
                                    <h4 className='fw-bold text-muted mx-2'>
                                        <input 
                                        checked={excludeSpecialServices}
                                        onChange={handleCheckboxChange}
                                        type='checkbox' 
                                        className='fw-bold text-muted mx-3' />
                                        Exclude Special
                                        </h4>
                                </label>
                                <Button
                                variant="primary"
                                onClick={applyFilter}
                                disabled={loading}
                                >
                                {loading ? (
                                    <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    {' Updating...'}
                                    </>
                                ) : (
                                    'Update'
                                )}
                                </Button>
                            </div>
                        </div>

                    <KTCardBody className='py-4'>
                        <div className='table-responsive'>
                            <MonthlyAttendanceTable />
                        </div>
                        <span>* - Special Service/Event</span>
                    </KTCardBody>
                </KTCard>
            </div>

        </div>

    )
}

export default MonthlyAttendance; 