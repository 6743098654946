import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { Settings } from './Settings'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Account',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>

        <Route
          path='settings'
          element={
            <>

              <PageTitle breadcrumbs={usersBreadcrumbs}>Setting</PageTitle>
              <Settings />
            </>
          } />

      </Route>


      <Route index element={<Navigate to='/apps/account/settings' />} />
    </Routes>
  )
}

export default UsersPage
